import React, { useEffect, useState, useContext } from 'react'
import {Box, Typography, Grid, Button} from '@mui/material'
import {styled} from '@mui/material/styles'

import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import EditIconDialog from 'components/dialog/EditIconDialog'
import EditStepDialog from 'components/dialog/EditStepDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import SecureLS from 'secure-ls'

// Icons
import ApplyIcon from '@mui/icons-material/Description'
import ExamIcon from '@mui/icons-material/Assignment'
import InterviewIcon from '@mui/icons-material/HeadsetMic'
import FinalInterviewIcon from '@mui/icons-material/Person'
import UploadIcon from '@mui/icons-material/AttachFile'

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'HKGrotesk, sans-serif',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontSize: '2rem',
}))

const StepTitle = styled(Typography)(({theme}) => ({
  fontFamily: 'CircularStd, sans-serif',
  fontWeight: 700,
  color: theme.palette.text.primary,
  textAlign: 'center',
}))

const StepSubtitle = styled(Typography)(({theme}) => ({
  fontFamily: 'CircularStd, sans-serif',
  fontWeight: 700,
  color: theme.palette.secondary.main || '#F8B64C',
  textAlign: 'center',
  marginTop: theme.spacing(1),
}))

const stepIconsDefault = [
  <ApplyIcon sx={{ fontSize: 40, marginBottom: 2 }} />,
  <ExamIcon sx={{ fontSize: 40, marginBottom: 2 }} />,
  <InterviewIcon sx={{ fontSize: 40, marginBottom: 2 }} />,
  <FinalInterviewIcon sx={{ fontSize: 40, marginBottom: 2 }} />,
  <UploadIcon sx={{ fontSize: 40, marginBottom: 2 }} />,
];

const stepTexts = [ 'Submission of Documents', 'Online Tests', 'Online Interview' , 'Final Interview', 'Uploading of Requirements' ];

const ls = new SecureLS({encodingType: 'aes'})

export default function CareerHowItWorks({ data }) {
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['How it Works']); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');
  const [stepTextFields, setStepTextFields] = useState(stepTexts)
  const [openStepDialogIndex, setOpenStepDialogIndex] = useState(null);
  const [stepDialogValue, setStepDialogValue] = useState('');
  const [stepIcons, setStepIcons] = useState([0,1,2,3,4])
  const [editIconIndex, setEditIconIndex] = useState(null);
  const [editIconValue, setEditIconValue] = useState(null);

  useEffect(() => {
    const { crmCareerHowText, careerHowItWorks } = data || {};

    const storedText = ls.get('crmCareerHowText');
    const howItWorksText = storedText || crmCareerHowText || 'How it Works';
    setTextFields([howItWorksText]);

    const dataSteps = careerHowItWorks && Array.isArray(careerHowItWorks)
        ? careerHowItWorks.map(step => step.title) 
        : stepTexts;

    const storedIcons = ls.get('crmCareerHowIcons');
    const dataIcons = careerHowItWorks && Array.isArray(careerHowItWorks)
        ? careerHowItWorks.map(step => step.icon)
        : [];

    if (storedIcons) {
        try {
            setStepIcons(storedIcons);
        } catch (error) {
            console.error('Error parsing stored icons:', error);
            setStepIcons(dataIcons.length ? dataIcons : [0,1,2,3,4]);
        }
    }

    const storedSteps = ls.get('crmCareerHowSteps');
    if (storedSteps) {
        try {
            setStepTextFields(JSON.parse(storedSteps));
        } catch (error) {
            console.error('Error parsing stored steps:', error);
            setStepTextFields(dataSteps);
        }
    } else {
        setStepTextFields(dataSteps);
    }

}, [data]);

  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
    setEditIconIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmCareerHowText', newValues[0]); 
    }

  };

  const handleEditIcon = (index, value) => {
    setEditIconIndex(index);
    setEditIconValue(value);
  };

  const handleIconChange = (currentIndex, newIcon) => {
    const updatedStepIcons = [...stepIcons];
    updatedStepIcons[editIconIndex] = newIcon;
    setStepIcons(updatedStepIcons);
    ls.set('crmCareerHowIcons', updatedStepIcons);
  };

  const handleOpenStepDialog = (index) => {
    setOpenStepDialogIndex(index);
    setStepDialogValue(stepTextFields[index]);
  };
  
  const addStep = () => {
    const updatedStepTextFields = [...stepTextFields, 'Sample Step Text']
    const updatedStepIcon = [...stepIcons, stepIcons.length > 4 ? 4 : stepIcons.length]
    setStepTextFields(updatedStepTextFields)
    setStepIcons(updatedStepIcon)
    ls.set('crmCareerHowSteps', JSON.stringify(updatedStepTextFields));
    ls.set('crmCareerHowIcons', updatedStepIcon);
  }
  
  const removeStep = (index) => {
    const updatedStepTextFields = stepTextFields.filter((_, i) => i !== index)
    const updatedStepIcons = stepIcons.filter((_, i) => i !== index)
    setStepTextFields(updatedStepTextFields)
    setStepIcons(updatedStepIcons)
    ls.set('crmCareerHowSteps', JSON.stringify(updatedStepTextFields));
    ls.set('crmCareerHowIcons', updatedStepIcons);
  }

  return (
    <Box sx={{py: 8}}>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <EditIconDialog
        open={editIconIndex !== null}
        onClose={handleDialogClose}
        stepIndex={editIconIndex}
        stepValue={editIconValue}
        onIconChange={handleIconChange}
        stepIconsDefault={stepIconsDefault}
      />
      <EditStepDialog
        open={openStepDialogIndex !== null}
        value={stepDialogValue}
        onClose={() => setOpenStepDialogIndex(null)}
        onSave={(value) => {
          const newStepTextFields = [...stepTextFields];
          newStepTextFields[openStepDialogIndex] = value;
          setStepTextFields(newStepTextFields);
          setOpenStepDialogIndex(null);
          ls.set('crmCareerHowSteps', JSON.stringify(newStepTextFields));
        }}
        onChange={setStepDialogValue}
      />
      <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
      <Title variant="h4">
        {textFields[0].split(' ').slice(0, -1).join(' ')}{' '}
        <Typography
          component="span"
          variant="h4"
          sx={{
            color: theme => theme.palette.secondary.main || '#F8B64C',
          }}
        >
          {textFields[0].split(' ').slice(-1)[0]}
        </Typography>
      </Title>
      </EditableItemWrapper>
      <Grid container spacing={4} justifyContent="center">
        {stepTextFields.map((step, index) => (
          <Grid item xs={6} sm={2} md={2} key={index}>
            <Box textAlign="center">
              <EditableItemWrapper onClick={() => !!isEditMode && handleEditIcon(index, stepIcons[index])}>
                {stepIconsDefault[stepIcons[index]]}
              </EditableItemWrapper>
              <StepSubtitle>Step {index + 1}</StepSubtitle>
              <EditableItemWrapper onClick={() => !!isEditMode && handleOpenStepDialog(index)}>
                <StepTitle>{step}</StepTitle>
              </EditableItemWrapper>
              {!!isEditMode && <Button onClick={() => removeStep(index)}>Remove Step</Button> } 
            </Box>
          </Grid>
        ))}
        {!!isEditMode && (
        <Grid item xs={6} sm={2} md={2}>
          <Box textAlign="center" sx={{mt: 8}}>
            <Button sx={{border: '3px dashed rgba(0, 0, 0, 0.8)'}} onClick={addStep}>Add Step</Button>
          </Box>
        </Grid> 
        )}
      </Grid>
    </Box>
  )
}