// SubdomainHandler.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {PreviewPage} from '../../app/pages/crm/preview/index';
import {checkSubdomain} from 'api/private/crm/index';

const SubdomainHandler = ({ subdomain }) => {
  const [subdomainData, setSubdomainData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkSubdomain(subdomain);
        if (!response) {
          setError('Domain not found');
          setSubdomainData({});
          return;
        }
        setSubdomainData(response.layout);
      } catch (err) {
        setError('Error fetching subdomain data');
      }
    };

    fetchData();
  }, [subdomain]);

  useEffect(() => {
    console.log('error', error);
  }, [error]);

  return (
    subdomainData ? (subdomainData.companyName ? (
      <PreviewPage subdomainLayoutData={subdomainData}/>
    ) : (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
       <h1 style={{fontSize: '3rem'}}>Domain not found</h1>
      </div>
    )) : (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <h1 style={{fontSize: '3rem'}}>Loading...</h1>
      </div>
    )
  );
};

export default SubdomainHandler;
