import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
  Slide
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SecureLS from 'secure-ls';
import { activateTemplate, deactivateTemplate } from 'api/private/crm';
import templateImg from '../templates/first-template/assets/template.png'; // Correct the import path
import { LocalSeeOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  overflow: 'hidden',
  width: '30%',
  height: '50%',
  '&:hover img': {
    opacity: 0.5,
  },
  '&:hover .overlay': {
    display: 'flex',
  }
}));

const Overlay = styled(Box)(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
}));

export default function TemplateDialog({ openTemplate, handleCloseTemplate, onSelect, activeTemplate, setActiveTemplate, setThemeColors  }) {
  const ls = new SecureLS({ encodingType: 'aes' });

  const templates = [
    { id: 1, img: templateImg }
  ];

  const handleSelect = async (id) => {
    if (activeTemplate === id) {
      const response = await deactivateTemplate(); 
      if (response) {
        console.log('Template deactivated successfully:', response);
        onSelect(null);
        setActiveTemplate(null);
        
        const keysToRetain = [
          'route', 'token', 'applicants', 'company', 'ud', 
          'uid', 'persist:v713-demo1-auth', '_secure__ls__metadata', 'iconify-version',
          'mediaAssets'
        ];

        Object.keys(localStorage).forEach(key => {
          if (!keysToRetain.includes(key)) {
            console.log(key);
            localStorage.removeItem(key);
          }
        });
    
        localStorage.removeItem('layoutData');
        setThemeColors({ primaryColor: '#000000', secondaryColor: '#FFA500' });
        localStorage.setItem('layoutData', JSON.stringify({primaryColor: '#000000', secondaryColor: '#FFA500'})); 
      } else {
        console.error('Failed to deactivate template');
      }
    } else {
      onSelect(id);
      
      const response = await activateTemplate(id);
      if (response) {
        console.log('Template activated successfully:', response);
        setActiveTemplate(id);
      } else {
        console.error('Failed to activate template');
      }
    }
  
    handleCloseTemplate();
  };

  return (
    <Dialog
      open={openTemplate}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseTemplate}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '80vh',
          maxHeight: '80vh',
        }
      }}
    >
      <DialogTitle id="alert-dialog-slide-title">{'Template'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Choose Template.
        </DialogContentText>
        {templates.map((template) => (
          <ImageContainer key={template.id}>
            <img src={template.img} alt={`Template ${template.id}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            <Overlay className="overlay">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSelect(template.id)}
              >
                {activeTemplate === template.id ? 'Deactivate' : 'Activate'}
              </Button>
            </Overlay>
          </ImageContainer>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTemplate} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
