import React, { useState } from 'react';
import SecureLS from 'secure-ls';
import { Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Button, 
    TextField } from '@mui/material';

const ls = new SecureLS({ encodingType: 'aes' });

const TrackingGADialog = ({ openTracking, handleCloseTracking, handleSaveGA }) => {
  const [trackingCode, setTrackingCode] = useState('');

  const handleSave = () => {
    
    // ls.set('gaTrackingCode', trackingCode)
    handleSaveGA(trackingCode)
    handleCloseTracking();
  };

  return (
    <div>
      <Dialog open={openTracking} onClose={handleCloseTracking}>
        <DialogTitle>Google Analytics Tracking Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add your Google Analytics tracking code here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="tracking-code"
            label="Tracking Code"
            type="text"
            fullWidth
            variant="outlined"
            value={trackingCode}
            onChange={(e) => setTrackingCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTracking}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrackingGADialog;
