import React, { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Grid, Button, Link, Box, CircularProgress, Backdrop } from '@mui/material';
import { alpha, lighten } from '@mui/material/styles';
import { motion } from 'framer-motion';


import layoutImage from '../../assets/layout1-agency.jpg';
import cover from '../../assets/wavy-two.png';
import ImageUploadDialog from '../../../../image/ImageWithDialog';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import SecureLS from 'secure-ls'

const RootStyle = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
}));

const ls = new SecureLS({encodingType: 'aes'})

export default function LandingBusiness(data) {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(false);
  
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['Apply online', 'Employee Login']); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([layoutImage, cover]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    const passedData = data?.data || {};
    const storedApplyOnlineText = ls.get('crmLandingBusinessApplyOnlineText');
    const storedEmployeeLoginText = ls.get('crmLandingBusinessEmployeeLoginText');
    const storedImage = ls.get('crmLandingBusinessImage');
    console.log('passedData', passedData)
    setTextFields([
      storedApplyOnlineText || passedData?.applyOnlineText || 'Apply online', 
      storedEmployeeLoginText || passedData?.employeeLoginText || 'Employee Login'
    ]);
    console.log('stored image: ', storedImage)
    setImageUrl(storedImage || passedData?.layoutImage || [layoutImage, cover]);

  }, []);


  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmLandingBusinessApplyOnlineText', newValues[0]);
    } else if (openDialogIndex === 1) {
      ls.set('crmLandingBusinessEmployeeLoginText', newValues[1]);
    }

  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    handleImageLoading()
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmLandingBusinessImage', updatedImages); // Save the new image in local storage
    handleImageLoaded()
  };

  const handleImageLoading = () => {
    setImageLoading(true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <>
    <EditTextDialog
      open={openDialogIndex !== null}
      value={dialogValue}
      onClose={handleDialogClose}
      onSave={handleDialogSave}
      onChange={setDialogValue}
    />
    <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
    />
    <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: 5 })}
      open={imageLoading}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
    
    <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(1)}>
    <RootStyle
      sx={{
        backgroundImage: `url(${imageUrl[1] !== 'wavy-two' ? imageUrl[1] : cover})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 130%',
        backgroundColor: '#FFFFFF',
        mt: { md: 5, xs: 10 },
        paddingTop: { md: 8, xs: 0 },
        paddingBottom: { md: 8, xs: 0 }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', ml: { xs: 0 } }}>
          <Grid item xs={12} md={8} sx={{ pl: { xs: '0 !important' } }}>
            <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)}>
              <Box
                component="img"
                alt="Layout Agency"
                style={imageLoading ? { display: 'none' } : { display: 'block' }}
                onLoad={handleImageLoaded}
                src={imageUrl[0] !== 'layout1-agency' ? imageUrl[0] : layoutImage}
                sx={{ width: '100%', height: { md: 480, xs: 300 }, objectFit: 'cover' }}
              />
            </EditableItemWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div style={{ marginTop: 0, width: '100%' }}>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
                <Typography
                  variant="h5"
                  sx={{
                    color: '#000000',
                    margin: 'auto',
                    textAlign: 'center',
                    mt: { xs: 0, sm: 0, md: 0 },
                    mb: { xs: 3 },
                    fontFamily: 'HKGrotesk, sans-serif',
                    fontWeight: 'bold'
                  }}
                  component="h2"
                >
                  {textFields[0]}
                </Typography>
              </EditableItemWrapper>
              <Link
                color="common.white"
                href="/apply"
                sx={{
                  display: 'block',
                  width: '100%',
                  '&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                <Button
                  component="h6"
                  variant="contained"
                  size="large"
                  sx={{
                    width: '100%',
                    marginLeft: '0',
                    fontSize: 18,
                    textTransform: 'none',
                    my: { xs: 1, md: 3 },
                    transition: 'all 0.4s ease',
                    backgroundColor: theme.palette.primary.main || '#007bff',
                    color: '#ffffff', 
                    boxShadow: 'none',
                    '&:hover': {
                      transition: 'all 0.4s ease',
                      transform: 'scale(1,1)',
                      bbackgroundColor: lighten(theme.palette.primary.main || '#0056b3', 0.2),
                      color: '#ffffff',
                      fontFamily: 'HKGrotesk, sans-serif',
                    }
                  }}
                >
                  Click here
                </Button>
              </Link>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(1)}>
                <Typography
                  variant="h5"
                  sx={{ color: '#000000', mt: 1, textAlign: 'center', fontFamily: 'HKGrotesk, sans-serif', fontWeight: 'bold' }}
                  component="p"
                >
                  {textFields[1]}
                </Typography>
              </EditableItemWrapper>
              <Link
                color="common.white"
                href={process.env.REACT_APP_MEMBER_URI}
                sx={{
                  display: 'block',
                  width: '100%',
                  '&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                <Button
                  component="h6"
                  variant="contained"
                  size="large"
                  sx={{
                    width: '100%',
                    marginLeft: '0',
                    fontSize: 18,
                    textTransform: 'none',
                    my: { xs: 1, md: 3 },
                    transition: 'all 0.4s ease',
                    backgroundColor: theme.palette.primary.main || '#007bff',
                    color: '#ffffff', 
                    boxShadow: 'none',
                    '&:hover': {
                      transition: 'all 0.4s ease',
                      transform: 'scale(1,1)',
                      bbackgroundColor: lighten(theme.palette.primary.main || '#0056b3', 0.2),
                      color: '#ffffff',
                      fontFamily: 'HKGrotesk, sans-serif'
                    }
                  }}
                >
                  Click here
                </Button>
              </Link>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
    </EditableItemWrapper>
    </>
  );
}