import React, { useEffect, useState, useContext } from 'react'
import {styled, useTheme} from '@mui/material/styles'
import {Container, Typography, Grid, Backdrop, CircularProgress} from '@mui/material'
import {motion} from 'framer-motion'
import cover from '../../assets/wavy-two.png'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import ImageUploadDialog from '../../../../image/ImageWithDialog';
import SecureLS from 'secure-ls'

const RootStyle = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.primary.main || '#FBBA37',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}))

const ls = new SecureLS({encodingType: 'aes'})

export default function LandingCommitment( data ) {
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState([`Our Commitment`, `We foster ongoing employee involvement while delivering outstanding services to our clients.`]); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([cover]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    const passedData = data?.data || {}; 
    const storedTitle = ls.get('crmLandingCommitmentTitle');
    const storedDescription = ls.get('crmLandingCommitmentDescription');
    const storedImage = ls.get('crmLandingCommitmentImage');
  
    setTextFields([
      storedTitle || passedData?.commitmentTitle || 'Our Commitment',
      storedDescription || passedData?.commitmentDescription || 'We foster ongoing employee involvement while delivering outstanding services to our clients.'
    ]);

    // Load the image from local storage when the component mounts
      setImageUrl(storedImage || passedData?.backgroundImage || [cover]);
  }, [data]);

  useEffect(() => {
    
  }, []);

  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);
    ls.set('crmLandingCommitmentTitle', newValues[0]);
    ls.set('crmLandingCommitmentDescription', newValues[1]);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    handleImageLoading();
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmLandingCommitmentImage', updatedImages); // Save the new image in local storage
    handleImageLoaded()
  };

  const handleImageLoading = () => {
    setImageLoading(true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <>
    <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: 5 })}
      open={imageLoading}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
    <EditTextDialog
      open={openDialogIndex !== null}
      value={dialogValue}
      onClose={handleDialogClose}
      onSave={handleDialogSave}
      onChange={setDialogValue}
    />
    <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
    />
    <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)}>
    <RootStyle
      sx={{
        backgroundImage: `url(${imageUrl[0] !== 'wavy-two' ? imageUrl[0] : cover})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 30%',
        height: {md: '400px', xs: '200px'},
        pt: {md: 10, xs: 10},
        pb: {md: 10, xs: 10},
      }}
      className="overlayBg"
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{width: '100%', ml: {xs: 0}}}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: {xs: '0 !important', md: '40px !important'},
            }}
          >
            <motion.div style={{marginTop: 0}}>
              <>
                <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'common.white',
                      margin: 'auto',
                      mt: {xs: 0, sm: 0, md: 0},
                      textAlign: 'center',
                      fontFamily: 'HKGrotesk, sans-serif',
                    }}
                    component="h2"
                  >
                    {textFields[0]}
                  </Typography>
                </EditableItemWrapper>
                <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(1)}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'common.white',
                      margin: 'auto',
                      mt: {xs: 2, sm: 0, md: 2},
                      textAlign: 'center',
                      fontWeight: 400,
                      fontFamily: 'Circular Std, sans-serif',
                    }}
                    component="h2"
                  >
                    {textFields[1]}
                  </Typography>
                </EditableItemWrapper>
              </>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
    </EditableItemWrapper>
    </>
  )
}