import React, { useEffect, useState, useContext } from 'react'
import { AppBar, Toolbar, Container, Button, Box, Link, IconButton, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import useMediaQuery from '@mui/material/useMediaQuery'
import defaultLogo from '../assets/logo.png'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import ImageUploadDialog from '../../../image/ImageWithDialog';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'

import SecureLS from 'secure-ls'

const Logo = styled('img')({
  height: '64px',
  width: 'auto',
})

const NavLinks = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  '& a': {
    margin: '0 15px',
    color: '#000000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide links on smaller screens
  },
}))

const DropdownMenu = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
}))

const ls = new SecureLS({encodingType: 'aes'})

export default function MainHeader({ onNavigate, headerData }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const context = useContext(EditModeContext);

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([logoSrc]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    const storedImage = ls.get('crmMainHeaderImage');
    const backendLogo = storedImage || headerData?.headerImages || [defaultLogo];
    setImageUrl(backendLogo);
  }, [headerData]);

  const logoSrc = headerData?.logo || defaultLogo
  const links = headerData?.links || [
    { label: 'Home', url: 'home' },
    { label: 'About Us', url: 'about' },
    { label: 'Career Opportunities', url: 'career' },
    { label: 'News & Events', url: 'news' },
    { label: 'Contact Us', url: 'contact' },
  ];
  const applyNowButtonText = headerData?.applyNowButtonText || 'Apply Now'

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const { isEditMode } = context || {}

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmMainHeaderImage', updatedImages); // Save the new image in local storage
  };

  return (
    <>
    <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleImageUpload}
        currentImage={imageUrl[selectedImageIndex]}
    />
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {
            !!isEditMode ? (
              <>
                <EditableItemWrapper onClick={() => handleImageClick(0)} sx={{display: 'flex', alignItems: 'center'}}>
                  <Link>
                    <Logo src={imageUrl[0] !== 'logo' ? imageUrl[0] : defaultLogo} alt="Logo" />
                  </Link>
                </EditableItemWrapper>
              </>
            ) : (
              <>
                <Link href="#" onClick={() => onNavigate('home') } sx={{display: 'flex', alignItems: 'center'}}>
                  <Logo src={imageUrl[0] !== 'logo' ? imageUrl[0] : defaultLogo} alt="Logo" />
                </Link>
              </>
            )
          }
          {isMobile ? (
            <DropdownMenu>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {links.map(link => (
                  <MenuItem
                    key={link.label}
                    onClick={() => {
                      onNavigate(link.label.toLowerCase().replace(/\s+/g, '')) // Convert label to a URL-friendly format
                      handleMenuClose()
                    }}
                  >
                    {link.label}
                  </MenuItem>
                ))}
              </Menu>
            </DropdownMenu>
          ) : (
            <NavLinks>
              <Link onClick={() => onNavigate('home')} underline="none">
                {links[0].label}
              </Link>
              <Link onClick={() => onNavigate('about')} underline="none">
                {links[1].label}
              </Link>
              <Link onClick={() => onNavigate('career')} underline="none">
                {links[2].label}
              </Link>
              <Link onClick={() => onNavigate('news')} underline="none">
                {links[3].label}
              </Link>
              <Link onClick={() => onNavigate('contact')} underline="none">
                {links[4].label}
              </Link>
            </NavLinks>
          )}

          {!isMobile && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme => theme.palette.primary.main || '#FDC22F',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: theme => theme.palette.primary.dark || '#E6B022',
                  color: '#ffffff',
                },
              }}
              onClick={() => onNavigate('apply')} // Use onNavigate instead of href
            >
              {applyNowButtonText}
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
    </>
  )
}
