import React, { useEffect, useState, useContext } from 'react'
import {styled} from '@mui/material/styles'
import {Container, Typography, Grid, Link, Button} from '@mui/material'
import {motion} from 'framer-motion'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import SecureLS from 'secure-ls'

const RootStyle = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  overflow: 'hidden',
  position: 'relative',
  height: 300,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center',
}))

const ls = new SecureLS({encodingType: 'aes'})

export default function LandingNeedManpower({data, handleNavigation }) {
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState([`Looking for Staffing Services?`]); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  useEffect(() => {
    const passedData = data?.data || {}; 
    const storedText = ls.get('crmLandingNeedManpowerText');
  
    setTextFields([
      storedText || passedData?.needManpowerText || 'Need Manpower? Contact us today to get started.'
    ]);
  }, [data]);

  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newText = dialogValue;
    setTextFields([newText]);
    ls.set('crmLandingNeedManpowerText', newText);
  };

  return (
    <RootStyle>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{width: '100%', ml: {xs: 0}}}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              pl: {xs: '0 !important', md: '40px !important'},
            }}
          >
            <motion.div style={{marginTop: 0}}>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
                <Typography
                  variant="h4"
                  sx={{
                    color: 'common.black',
                    margin: 'auto',
                    mt: {xs: 5, sm: 0, md: 0},
                    fontWeight: 700,
                  }}
                  component="h2"
                >
                  {textFields[0]}
                </Typography>
              </EditableItemWrapper>
              <Button
                  size="large"
                  variant="contained"
                  sx={theme => ({
                    my: 2,
                    width: '200px',
                    backgroundColor: theme.palette.primary.main || '#F8B64C',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark || '#e0a238',
                    },
                    color: 'white',
                  })}
                  onClick={() => handleNavigation('contact')}
                >
                  Contact Us
                </Button>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  )
}