import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for Quill
import PropTypes from 'prop-types';

const RichTextEditor = ({openTextDialog, oncloseDialog, onUpload, value }) => {
    const [html, setHtml] = useState(value)


     
     useEffect(() => {
        setHtml(value);
    }, [value]);

    const handleChange = (html) => {
        setHtml(html)
    };


    const handleSave = () => {
        onUpload(html)
        oncloseDialog()
    }

    return (
        <Dialog
            open={openTextDialog}
            onClose={oncloseDialog}
        >
            <DialogTitle id="alert-dialog-slide-title">Text Editor</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">Update text</DialogContentText>
                <ReactQuill
                    onChange={handleChange}
                    value={html}
                    modules={RichTextEditor.modules}
                    formats={RichTextEditor.formats}
                    bounds={'.app'}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={oncloseDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save Text
                </Button>
            </DialogActions>
        </Dialog>
    );
};

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ],
    clipboard: {
        matchVisual: false,
    }
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
];

/* 
 * PropType validation
 */
RichTextEditor.propTypes = {
    placeholder: PropTypes.string,
};

/* 
 * Render component on page
 */
export default RichTextEditor;
