// const API = process.env.REACT_APP_PUBLIC_URL;
// const SECRET = process.env.REACT_APP_SECRET;
// const TITLE = process.env.REACT_APP_TITLE;
// const APICRM = process.env.REACT_APP_LOCAL;
const API = "https://api.starjobs.com.ph/webservice-hr-api";
const SECRET = "CODEX@123";
const TITLE = "APEX";
const APICRM = "https://sparkles-backend.onrender.com/";

export { APICRM, API, SECRET, TITLE };
