import React from 'react'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import MainLayout from 'components/crm/templates/first-template/layout'

export const PreviewPage = ({ subdomainLayoutData }) => {
  const layoutData = JSON.parse(localStorage.getItem('layoutData')) || subdomainLayoutData || {}

  const theme = createTheme({
    palette: {
      primary: {
        main: layoutData.primaryColor || '#000000',
      },
      secondary: {
        main: layoutData.secondaryColor || '#ffffff',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <MainLayout
        header={layoutData.header}
        footer={layoutData.footer}
        metaTagsData={layoutData.meta}
        homePageData={layoutData.homePage}
        careerPageData={layoutData.careerPage}
        contactPageData={layoutData.contactPage}
        newsPageData={layoutData.newsPage}
        aboutPageData={layoutData.aboutPage}
      />
    </ThemeProvider>
  )
}
