import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Slide,
  Box
} from '@mui/material'
import {HexColorPicker} from 'react-colorful'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ThemeDialog({openMedia, handleCloseMedia, setThemeColors, primaryColor, secondaryColor}) {
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [localPrimaryColor, setLocalPrimaryColor] = useState(primaryColor || '#000000');
  const [localSecondaryColor, setLocalSecondaryColor] = useState(secondaryColor || '#FFA500');
  const [showColorPicker, setShowColorPicker] = useState(true)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setThemeColors({primaryColor: localPrimaryColor, secondaryColor: localSecondaryColor})
  }, [localPrimaryColor, localSecondaryColor, setThemeColors])

  const handleInputChange = e => {
    const {name, value} = e.target
    switch (name) {
      case 'companyName':
        setCompanyName(value)
        break
      case 'companyAddress':
        setCompanyAddress(value)
        break
      case 'contactEmail':
        setContactEmail(value)
        break
      default:
        break
    }
  }

  const validate = () => {
    const tempErrors = {}
    if (!companyName) tempErrors.companyName = "Company's name is required."
    if (!companyAddress) tempErrors.companyAddress = "Company's address is required."
    if (!contactEmail) tempErrors.contactEmail = 'Contact email is required.'
    else if (!/\S+@\S+\.\S+/.test(contactEmail)) tempErrors.contactEmail = 'Email is not valid.'
    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const handleChooseThemeClick = () => {
    setShowColorPicker(!showColorPicker)
  }

  const layoutData = JSON.parse(localStorage.getItem('layoutData')) || {}; 
  const handleSaveTheme = () => {
   
    layoutData.primaryColor = localPrimaryColor;
    layoutData.secondaryColor = localSecondaryColor;
  
    localStorage.setItem('layoutData', JSON.stringify(layoutData)); 
    setThemeColors({ primaryColor: localPrimaryColor, secondaryColor: localSecondaryColor }); 
    handleCloseMedia(); 
  };

  const handleClose = () =>{
    setThemeColors({ primaryColor: layoutData.primaryColor, secondaryColor: layoutData.secondaryColor }); 
    handleCloseMedia();
  }


  return (
    <Dialog
      open={openMedia}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseMedia}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      // maxWidth={false}
      // sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 'none' } }} 
    >
      <DialogTitle id="alert-dialog-slide-title">{'Manage Template Theme Colors'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">Choose Theme Color</DialogContentText>
       
        {/* Choose Theme Section */}
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" color="primary" onClick={handleChooseThemeClick}>
            Choose Theme
          </Button>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: localPrimaryColor,
              marginLeft: 1,
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginLeft: 2,
            }}
          />
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: localSecondaryColor,
              marginLeft: 1,
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginLeft: 2,
            }}
          />
        </Box>
        {showColorPicker && (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <DialogContentText>Primary Color</DialogContentText>
              <HexColorPicker color={localPrimaryColor} onChange={setLocalPrimaryColor} />
              <TextField
              label="Primary Color"
              variant="outlined"
              value={localPrimaryColor}
              onChange={(e) => setLocalPrimaryColor(e.target.value)}
              sx={{ mt: 2 }}
            />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <DialogContentText>Secondary Color</DialogContentText>
              <HexColorPicker color={localSecondaryColor} onChange={setLocalSecondaryColor} />
              <TextField
              label="Secondary Color"
              variant="outlined"
              value={localSecondaryColor}
              onChange={(e) => setLocalSecondaryColor(e.target.value)}
              sx={{ mt: 2, ml: 2 }}
            />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveTheme} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
