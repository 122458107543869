// material
import React, { useContext, useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Grid } from '@mui/material';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import SecureLS from 'secure-ls'

//
import { motion } from 'framer-motion';
//import { varFadeInUp } from '../../animate';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  overflow: 'hidden',
  position: 'relative',
  height: 200,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center'
}));

const ls = new SecureLS({encodingType: 'aes'})

export default function LandingCenterText( data ) {
  const theme = useTheme();

  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['Grow with us. Start your job search here']); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  useEffect(() => {
    const passedData = data?.data || {}; 
  
    const storedMainText = ls.get('crmLandingCenterMainText');
    setTextFields([
      storedMainText || passedData?.mainText || 'Grow with us. Start your job search here'
    ]);
  }, [data]);

  const { isEditMode } = context || {};

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);
    ls.set('crmLandingCenterMainText', newValues[0]);
  };

  return (
    <RootStyle>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', ml: { xs: 0 } }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: { xs: '0 !important' } }}
          >
            <motion.div 
            //variants={varFadeInUp} 
            style={{ marginTop: 0 }}>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.text.primary, 
                    fontFamily: 'HKGrotesk, sans-serif',
                    fontWeight: theme.typography.fontWeightBold,
                    margin: 'auto',
                    textAlign: 'center'
                  }}
                  component="h2"
                >
                  {textFields[0]}
                </Typography>
              </EditableItemWrapper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}