import React, { useState, useContext, useEffect } from 'react';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, Typography, Select, MenuItem } from '@mui/material';

const EditMetaTagsDialog = ({ onClose, open, metaData, onSave }) => {
  const [metaTags, setMetaTags] = useState(metaData);

  const [selectedPage, setSelectedPage] = useState('home');

  const context = useContext(EditModeContext);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'charset') {
      setMetaTags({ ...metaTags, [name]: value });
      return;
    }

    if (name === 'viewport') {
      setMetaTags({ ...metaTags, [name]: value });
      return;
    }
    setMetaTags({ ...metaTags, [selectedPage]: { ...metaTags[selectedPage], [name]: value } });
  };

  const handleSelectChange = (event) => {
    setSelectedPage(event.target.value);
  };

  const handleResetTags = () => {
    setMetaTags(metaData)
  }

  useEffect(() => {
    setMetaTags(metaData)
  }, [metaData]);

  const { isEditMode } = context || {}

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(metaTags);
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Meta Tags</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Charset:</span> {metaTags.charset}
          </Typography>
        </DialogContentText>
        <TextField
          name="charset"
          label="Charset"
          value={metaTags.charset}
          onChange={handleChange}
        />
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Viewport:</span> {metaTags.viewport}
          </Typography>
        </DialogContentText>
        <TextField
          name="viewport"
          label="Viewport"
          value={metaTags.viewport}
          multiline
          rows={4}
          onChange={handleChange}
        />

        <Typography variant="h6" gutterBottom>
          <span style={{ fontWeight: 'bold' }}>Page Meta Tags</span>
        </Typography>

        <Select
          value={selectedPage}
          label="Page"
          onChange={handleSelectChange}
        >
          <MenuItem value="home">Home</MenuItem>
          <MenuItem value="about">About</MenuItem>
          <MenuItem value="career">Career</MenuItem>
          <MenuItem value="news">News</MenuItem>
          <MenuItem value="contact">Contact</MenuItem>
        </Select>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Title:</span> {metaTags[selectedPage].title }
          </Typography>
        </DialogContentText>
        <TextField
          name="title"
          label="Title"
          value={metaTags[selectedPage].title}
          onChange={handleChange}
        />

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Description:</span> {metaTags[selectedPage].description}
          </Typography>
        </DialogContentText>
        <TextField
          name="description"
          label="Description"
          value={metaTags[selectedPage].description}
          onChange={handleChange}
        />

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Keywords:</span> {metaTags[selectedPage].keywords}
          </Typography>
        </DialogContentText>
        <TextField
          name="keywords"
          label="Keywords"
          value={metaTags[selectedPage].keywords}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetTags}>Reset</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMetaTagsDialog;

