import React, {useState, useContext, useEffect} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, IconButton} from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper';
import SecureLS from 'secure-ls';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

const ls = new SecureLS({ encodingType: 'aes' });
// 
const defaultEvents = [
  {id: 1, date: '2022-01-01', event: 'New Year\'s Day', description: 'New Year\'s Day is a federal holiday in the United States.'},
  {id: 2, date: '2022-02-14', event: 'Valentine\'s Day', description: 'Valentine\'s Day is a special day for couples to celebrate their love.'},
  {id: 3, date: '2022-03-17', event: 'St. Patrick\'s Day', description: 'St. Patrick\'s Day is a day to celebrate Irish heritage and culture.'},
  {id: 4, date: '2022-04-15', event: 'Good Friday', description: 'Good Friday is a Christian holiday that commemorates the crucifixion of Jesus Christ.'},
  {id: 5, date: '2022-05-01', event: 'May Day', description: 'May Day is a traditional spring holiday in many countries.'},
  {id: 6, date: '2022-06-15', event: 'Father\'s Day', description: 'Father\'s Day is a special day to celebrate fathers and father figures.'},
  {id: 7, date: '2022-07-01', event: 'Canada Day', description: 'Canada Day is a national holiday in Canada.'},
  {id: 8, date: '2022-08-15', event: 'Assumption Day', description: 'Assumption Day is a Catholic holiday that commemorates the assumption of the Virgin Mary into heaven.'},
  {id: 9, date: '2022-09-01', event: 'Labor Day', description: 'Labor Day is a federal holiday in the United States that honors the contributions and achievements of American workers.'},
  {id: 10, date: '2022-10-01', event: 'Thanksgiving Day', description: 'Thanksgiving Day is a national holiday in the United States that celebrates the harvest and expresses gratitude for the blessings of the past year.'},
  {id: 11, date: '2022-11-01', event: 'Halloween', description: 'Halloween is a popular holiday in the United States that is celebrated on October 31st.'},
  {id: 12, date: '2022-12-25', event: 'Christmas Day', description: 'Christmas Day is a federal holiday in the United States that commemorates the birth of Jesus Christ.'},
]

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day}
      overlap="circular"
      badgeContent={isSelected ? <Box sx={{ backgroundColor: 'white', borderRadius: '100%' }}><EventAvailableIcon/></Box> : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function NewsCalendar({data}) {
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const context = useContext(EditModeContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [editDialogId, setEditDialogId] = useState(null);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [highlightedDays, setHighlightedDays] = useState([]);

  useEffect(() => {
    const loadEvents = async () => {
      const storedData = ls.get('crmNewsEvents') || data?.map(event => ({...event, id: event._id}));
      console.log('eto oh: ', storedData)
      const parsedData = storedData ? storedData : defaultEvents; // Fallback to 'data' prop if no stored data
      setEvents(parsedData);
    }
    loadEvents()
  }, []);

  useEffect(() => {
    setHighlightedDays(events.filter(event => event.date.slice(0, 7) === selectedDate.format('YYYY-MM')).map(event => parseInt(event.date.slice(8, 10))));
  }, [events]);

  useEffect(() => {
    console.log('Highlighted days:', highlightedDays);
  }, [highlightedDays]);
  
  useEffect(() => {
    console.log('Selected date:', selectedDate.format('YYYY-MM-DD'));
    setFilteredEvents(events.filter(event => event.date === selectedDate.format('YYYY-MM-DD')));
  }, [selectedDate, events]);

  const handleMonthChange = (date) => {
    setHighlightedDays(events.filter(event => date.format('YYYY-MM') === event.date.slice(0, 7)).map(event => parseInt(event.date.slice(8, 10))))
  }

  const { isEditMode } = context || {}

  const handleDialogOpen = (event) => {
    setOpenDialog(true);
    if (event) {
      setDialogTitle(event.event);
      setDialogDescription(event.description);
      setEditDialogId(event.id);
    }
  }
  
  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditDialogId(null);
  }
  
  const handleDialogSave = () => {
    const newEvents = [...events];
    const existingEvent = newEvents.find(event => event.id === editDialogId);
    if (existingEvent) {
      existingEvent.event = dialogTitle;
      existingEvent.description = dialogDescription;
    } else {
      newEvents.push({id: events.length + 1, date: selectedDate.format('YYYY-MM-DD'), event: dialogTitle, description: dialogDescription});
    }
    setEvents(newEvents);
    ls.set('crmNewsEvents', newEvents);
    handleDialogClose();
  }
  
  const handleRemove = (removeEvent) => {
    setEvents((prevEvents) => prevEvents.filter(event => event.id !== removeEvent));
    ls.set('crmNewsEvents', events.filter(event => event.id !== removeEvent));
  }
  
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                width: '100%',
                margin: '0 auto',
                padding: '20px 0',
                height: {
                  xs: '400px', // Height for extra small screens (mobile)
                  sm: '450px', // Height for small screens (tablets)
                  md: '500px', // Height for medium screens (desktops)
                  lg: '550px', // Height for large screens (desktops)
                },
                overflow: 'clip',
              }}
            >
              
                <DateCalendar
                sx={{
                  transform: {
                    xs: 'scale(1.10)',
                    sm: 'scale(1.20)',
                    md: 'scale(1.20)',
                    lg: 'scale(1.65)',
                  },
                  transition: 'transform 0.3s ease',
                  transformOrigin: 'top center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: {
                    xs: '400px',
                    sm: '450px',
                    md: '500px',
                    lg: '550px',
                  },
                  overflow: 'hidden',
                }}
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  onMonthChange={handleMonthChange}
                  onYearChange={handleMonthChange}
                  slots={{
                    day: ServerDay,
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    },
                  }}
                />
            </Box>
          </LocalizationProvider>
        </Col>
        <Col md={6}>
          <Box sx={{
                width: '100%',
                margin: '0 auto',
                padding: '20px 0',
                height: {
                  xs: '500px', // Height for extra small screens (mobile)
                  sm: '550px', // Height for small screens (tablets)
                  md: '600px', // Height for medium screens (desktops)
                  lg: '650px', // Height for large screens (desktops)
                },
                overflowY: 'auto',          // Enable vertical scrolling
                overflowX: 'hidden',
                wordWrap: 'break-word',     // Ensure long words break into the next line
                whiteSpace: 'normal',       // Allow text to wrap onto multiple lines
                maxWidth: '100%',            // Prevent text from exceeding its container's width
                alignItems: 'center'
              }}>
            <Typography variant="h6" gutterBottom>
              News and Events
            </Typography>
            {filteredEvents.length === 0 ? (
              <Typography variant="body1" color="textSecondary" align="center">
                No news or events here
              </Typography>
            ) : (
              <List>
                {filteredEvents.map(event => (
                  <ListItem key={event.id} secondaryAction={
                    isEditMode ? (
                      <>
                        <IconButton edge="end" onClick={() => handleDialogOpen(event)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={() => handleRemove(event.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : null
                  }>
                    <ListItemText primary={<Typography variant="h6">{event.event}</Typography>} secondary={<Typography style={{marginRight: '15px'}} variant='body1'>{event.description}</Typography>} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          {isEditMode && (
            <Button
              onClick={handleDialogOpen}
              variant="contained"
              color="primary"
              size="small"
              sx={{
                mt: 2,
                mb: 5,
              }}
            >
              Add Event
            </Button>
          )}
        </Col>
      </Row>
      <Dialog open={openDialog && editDialogId} onClose={handleDialogClose}>
        <DialogTitle>Edit Event</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Event Title"
            type="text"
            fullWidth
            value={dialogTitle}
            onChange={e => setDialogTitle(e.target.value)}
            variant="outlined"
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            margin="dense"
            id="description"
            label="Event Description"
            type="text"
            fullWidth
            multiline
            rows={6}
            value={dialogDescription}
            onChange={e => setDialogDescription(e.target.value)}
            variant="outlined"
            inputProps={{ maxLength: 510 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog && !editDialogId} onClose={handleDialogClose}>
        <DialogTitle>Add New Event</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-title"
            label="Event Title"
            type="text"
            fullWidth
            variant="outlined"
            value={dialogTitle}
            onChange={e => setDialogTitle(e.target.value)}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            margin="dense"
            id="new-description"
            label="Event Description"
            type="text"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={dialogDescription}
            onChange={e => setDialogDescription(e.target.value)}
            inputProps={{ maxLength: 510 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
      </Container>
  )
}

export default NewsCalendar

