import React, { useEffect, useState } from 'react';
import SecureLS from 'secure-ls';
import { Helmet } from 'react-helmet';
// components
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import LandingPage from '../pages/home';
import CareerPage from '../pages/careers';
import AboutPage from '../pages/aboutus';
import NewsPage from '../pages/newsandevents';
import ContactPage from '../pages/contactus';

import { getGATracker } from 'api/private/crm';

// ----------------------------------------------------------------------

const ls = new SecureLS({ encodingType: 'aes' });

function MetaTags({ charset, viewport, trackingCode }) {
  console.log('Tracking Code Meta =>', trackingCode)
  return (
    <Helmet>
      <meta charset={charset}/>
      <meta name="viewport" content={viewport} />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingCode}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingCode}');
        `}
      </script>
    </Helmet>
  );
}

export default function MainLayout({metaTagsData, headerData, footerData, homePageData, careerPageData, aboutPageData, contactPageData, newsPageData }) { // Accept the data as props
  const [currentPage, setCurrentPage] = useState('home');
  const [trackingCode, setTrackingCode] = useState('');
  const [metaData, setMetaData] = useState({
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1.0',
    site: {
      title: '',
      description: '',
      keywords: ''
    },
    home: {
      title: '',
      description: '',
      keywords: ''
    },
    about: {
      title: '',
      description: '',
      keywords: ''
    },
    career: {
      title: '',
      description: '',
      keywords: ''
    },
    news: {
      title: '',
      description: '',
      keywords: ''
    },
    contact: {
      title: '',
      description: '',
      keywords: ''
    }
  });

  useEffect(() => {
    const localResponseMeta = ls.get('crmPageMetaTags');
    const getTrackerData = async () => {

      const hostname = window.location.hostname;
      const domainParts = hostname.split('.');

      if (domainParts.length >= 2) {
        const subdomain = domainParts[0];
        const data = await getGATracker(subdomain);
        if (data) {
          console.log("DATA OF TRACKING CODE =>", data.body.googleAnalyticsTracker)
          setTrackingCode(data.body.googleAnalyticsTracker); 
        }
      } else {
        console.log('No subdomain');
        return null;
      }
    };
 
    if (localResponseMeta) {
      setMetaData(localResponseMeta);
    } else if (metaTagsData) {
      setMetaData(metaTagsData);
    }

    getTrackerData();
  }, []);

  const mapPageTitle = (page) => {
    switch (page.toLowerCase()) {
      case 'home':
        return 'home';
      case 'aboutus':
      case 'about':
        return 'about';
      case 'careeropportunities':
      case 'career':
        return 'career';
      case 'news&events':
      case 'news':
        return 'news';
      case 'contactus':
      case 'contact':
        return 'contact';
      default:
        return 'home'; 
    }
  };

  // Function to handle navigation
  const handleNavigation = (page) => {
    const mappedPage = mapPageTitle(page);
    setCurrentPage(mappedPage);
  };

  return (
    <>
      <MainHeader onNavigate={handleNavigation} headerData={headerData} /> 
      <MetaTags charset={metaData.charset} viewport={metaData.viewport} trackingCode={trackingCode} /> {/* Now passing the updated trackingCode */}
      <div>
        {currentPage === 'home' && <LandingPage metaData={metaData.home} homePageData={homePageData} handleNavigation={handleNavigation}/>} 
        {currentPage === 'about' && <AboutPage metaData={metaData.about} aboutPageData={aboutPageData}/>}
        {currentPage === 'career' && <CareerPage metaData={metaData.career} careerPageData={careerPageData} />}
        {currentPage === 'news' && <NewsPage metaData={metaData.news} newsPageData={newsPageData} />}
        {currentPage === 'contact' && <ContactPage metaData={metaData.contact} contactPageData={contactPageData} />}
      </div>

      <MainFooter footerData={footerData} />
    </>
  );
}
