import React, { useState, useEffect } from 'react';
import SecureLS from 'secure-ls';
import { Button, Box, Grid, AppBar, Toolbar, Typography, Divider, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Link } from '@mui/material';
import { Icon } from '@iconify/react';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TemplateDialog from 'components/crm/modal/template-modal';
import MediaDialog from 'components/crm/modal/media-modal';
import ThemeDialog from 'components/crm/modal/theme-color-modal';
import TrackingGADialog from 'components/crm/modal/trackingGA-modal';
import MainLayout from 'components/crm/templates/first-template/layout';
import Loader from 'components/preloader'; // Import the loader component
import { EditModeContext } from 'context/EditModeContext'; // Import your context
import { fetchAppliedLayout, publishTemplate, unpublishTemplate, editSaveLayout, addGATracker } from 'api/private/crm';
import EditMetaTagsDialog from 'components/crm/meta/EditMetaTagsDialog';

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  height:'auto',
  marginTop: '3vh'
}));

export const CrmEditorPage = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [openMeta, setOpenMeta] = useState(false);
  const [openTheme, setOpenTheme] = useState(false)
  const [openTracking, setOpenTracking] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
  const [primaryColor, setPrimaryColor] = useState(savedLayoutData.primaryColor || '#000000');
  const [secondaryColor, setSecondaryColor] = useState(savedLayoutData.secondaryColor || '#FFA500');
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});
  const [homePage, setHomePage] = useState({});
  const [careerPage, setCareerPage] = useState({});
  const [contactPage, setContactPage] = useState({});
  const [newsPage, setNewsPage] = useState({});
  const [aboutPage, setAboutPage] = useState({});
  const [hover, setHover] = useState(false);
  const [hoverPublish, setHoverPublish] = useState(false)
  const [hoverPreview, setHoverPreview] = useState(false)
  const [hoverEdit, setHoverEdit] = useState(false)
  const [hoverMeta, setHoverMeta] = useState(false)
  const [hoverMedia, setHoverMedia] = useState(false)
  const [metaData, setMetaData] = useState({
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1.0',
    site: {
      title: '',
      description: '',
      keywords: ''
    },
    home: {
      title: '',
      description: '',
      keywords: ''
    },
    about: {
      title: '',
      description: '',
      keywords: ''
    },
    career: {
      title: '',
      description: '',
      keywords: ''
    },
    news: {
      title: '',
      description: '',
      keywords: ''
    },
    contact: {
      title: '',
      description: '',
      keywords: ''
    }
  });

  const ls = new SecureLS({ encodingType: 'aes' });

  const companyName = ls.get('company')

  // const url = process.env.REACT_APP_DOMAIN === 'localhost' ? `http://${companyName}.localhost:${process.env.REACT_APP_PORT}` : `https://${companyName}.${process.env.REACT_APP_DOMAIN}`;
  const url = `https://${companyName}.sparkledev.online`;
  const [openPublishURL, setOpenPublishURL] = useState(false);

  const handlePublishURLClose = () => {
    setOpenPublishURL(false);
  };

  const handlePublishURLCopy = () => {
    navigator.clipboard.writeText(url);
    window.alert('Copied to clipboard!');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    const getLayout = async () => {
      setLoading(true);
      try {
        const response = await fetchAppliedLayout();
        const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {}; // Retrieve local storage data
        const localMetaResponse = ls.get('crmPageMetaTags');
        if(localMetaResponse){
          setMetaData(localMetaResponse);
        }

        if (response && response.layout) {
          const { templateName, meta, header, footer, homePage, aboutPage, careerPage, contactPage, newsPage, primaryColor: fetchedPrimaryColor, secondaryColor: fetchedSecondaryColor } = response.layout;
          // Use colors from local storage first, then fetched data
          const primaryColor = savedLayoutData.primaryColor || fetchedPrimaryColor || '#000000';
          const secondaryColor = savedLayoutData.secondaryColor || fetchedSecondaryColor || '#FFA500';

          setPrimaryColor(primaryColor);
          setSecondaryColor(secondaryColor);
          setHomePage(homePage);
          setCareerPage(careerPage);
          setContactPage(contactPage);
          setNewsPage(newsPage);
          setAboutPage(aboutPage);
          if (meta !== undefined) {
          setMetaData(meta);
          }

          setSelectedTemplate(
            <MainLayout
              meta={meta}
              headerData={header}
              footerData={footer}
              homePageData={homePage}
              careerPageData={careerPage}
              contactPageData={contactPage}
              newsPageData={newsPage}
              aboutPageData={aboutPage}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            />
          );

          const templateId = parseInt(templateName.split(' ')[1]);
          setActiveTemplate(templateId);

          const layoutToSave = {
            primaryColor: fetchedPrimaryColor,
            secondaryColor: fetchedSecondaryColor,
            meta,
            header,
            footer,
            homePage,
            careerPage,
            contactPage,
            newsPage,
            aboutPage
          };
          ls.set('fetchedLayoutData', JSON.stringify(layoutToSave));

          if (response.layout.hasOwnProperty('isPublished')) {
            console.log("isPublished before setting state:", response.layout.isPublished);
            setIsPublished(response.layout.isPublished);
          }
        } else {
          console.error('Layout data is not found in the response.');
        }
      } catch (error) {
        console.error("Failed to fetch the applied layout", error);
      } finally {
        setLoading(false);
      }
    };

    getLayout();

    if (!ls.get('mediaAssets')) {
      ls.set('mediaAssets', []);
    }
    
  }, [activeTemplate]);

  const handleSelectTemplate = (id) => {
    setLoading(true);
    if (id === null) {
      setSelectedTemplate(null); 
    } else {
      setSelectedTemplate(<MainLayout />); 
    }
    setLoading(false);
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
  };
  
  const handleCloseMedia = () => {
    setOpenMedia(false);
  };

  const handleCloseTheme = () => {
    setOpenTheme(false);
  };

  const handleCloseMeta = () => {
    setOpenMeta(false);
  };

  const handleCloseTracking = () =>{
    setOpenTracking(false);
  }

  const handleEditMode = async () => {
    setIsEditMode(prev => {
        if (prev) {
            try {
                const getParsedData = (data) => {
                  try {
                      return JSON.parse(data);
                  } catch (e) {
                      return null;
                  }
                };
            
                const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
                const fetchedLayoutData = getParsedData(ls.get('fetchedLayoutData')) || {}; // Use fetched data if no localStorage

                // Assemble layout data
                const layoutData = {
                    primaryColor: savedLayoutData.primaryColor || fetchedLayoutData.primaryColor || '#000000',
                    secondaryColor: savedLayoutData.secondaryColor || fetchedLayoutData.secondaryColor || '#FFA500',
                    meta: ls.get('crmPageMetaTags') || savedLayoutData?.meta || fetchedLayoutData?.meta || {
                      charset: 'utf-8',
                      viewport: 'width=device-width, initial-scale=1.0',
                      site: {
                        title: '',
                        description: '',
                        keywords: ''
                      },
                      home: {
                        title: '',
                        description: '',
                        keywords: ''
                      },
                      about: {
                        title: '',
                        description: '',
                        keywords: ''
                      },
                      career: {
                        title: '',
                        description: '',
                        keywords: ''
                      },
                      news: {
                        title: '',
                        description: '',
                        keywords: ''
                      },
                      contact: {
                        title: '',
                        description: '',
                        keywords: ''
                      }
                    },
                    header: {
                        headerImages: ls.get('crmMainHeaderImage') || savedLayoutData?.header?.logo || fetchedLayoutData?.header?.logo || ['logo'],
                        links: getParsedData(ls.get('crmMainHeaderLinks')) || savedLayoutData?.header?.links || fetchedLayoutData?.header?.links || [],
                        applyNowButtonText: ls.get('crmMainHeaderApplyNowButtonText') || savedLayoutData?.header?.applyNowButtonText || fetchedLayoutData?.header?.applyNowButtonText || 'Apply Now',
                    },
                    footer: {
                        footerImages: ls.get('crmMainFooterImage') || savedLayoutData?.footer?.logo || fetchedLayoutData?.footer?.logo || ['logo', 'wavy-two'],
                        officeAddress: ls.get('crmMainFooterTextFields')?.split('|')[0] || savedLayoutData?.footer?.officeAddress || fetchedLayoutData?.footer?.officeAddress || 'Default Address',
                        emailAddress: ls.get('crmMainFooterTextFields')?.split('|')[1] || savedLayoutData?.footer?.emailAddress || fetchedLayoutData?.footer?.emailAddress || 'default@example.com',
                        phoneNumber: ls.get('crmMainFooterTextFields')?.split('|')[2] || savedLayoutData?.footer?.phoneNumber || fetchedLayoutData?.footer?.phoneNumber || '123-456-7890',
                        footerText: ls.get('crmMainFooterTextFields')?.split('|')[3] || savedLayoutData?.footer?.footerText || fetchedLayoutData?.footer?.footerText || 'Default Footer Text',
                        iconLink: ls.get('crmMainFooterHrefLinks') || fetchedLayoutData?.footer?.iconLink || savedLayoutData?.footer?.iconLink || [{"icon":0,"alt":"Facebook","link":"https://www.facebook.com/syzygystaffing"}],
                    },
                    homePage: {
                        landingBusiness: {
                            applyOnlineText: ls.get('crmLandingBusinessApplyOnlineText') || savedLayoutData?.homePage?.landingBusiness?.applyOnlineText || fetchedLayoutData?.homePage?.landingBusiness?.applyOnlineText || 'Apply online',
                            employeeLoginText: ls.get('crmLandingBusinessEmployeeLoginText') || savedLayoutData?.homePage?.landingBusiness?.employeeLoginText || fetchedLayoutData?.homePage?.landingBusiness?.employeeLoginText || 'Employee Login',
                            applyButtonLink: ls.get('crmLandingBusinessApplyButtonLink') || savedLayoutData?.homePage?.landingBusiness?.applyButtonLink || fetchedLayoutData?.homePage?.landingBusiness?.applyButtonLink || '/apply',
                            employeeLoginLink: ls.get('crmLandingBusinessEmployeeLoginLink') || savedLayoutData?.homePage?.landingBusiness?.employeeLoginLink || fetchedLayoutData?.homePage?.landingBusiness?.employeeLoginLink || '/employee-login',
                            layoutImage: ls.get('crmLandingBusinessImage') || savedLayoutData?.homePage?.landingBusiness?.layoutImage || fetchedLayoutData?.homePage?.landingBusiness?.layoutImage || [],
                        },
                        landingCenterText: {
                            mainText: ls.get('crmLandingCenterMainText') || savedLayoutData?.homePage?.landingCenterText?.mainText || fetchedLayoutData?.homePage?.landingCenterText?.mainText || 'Grow with us. Start your job search here',
                        },
                        landingCommitment: {
                            commitmentTitle: ls.get('crmLandingCommitmentTitle') || savedLayoutData?.homePage?.landingCommitment?.commitmentTitle || fetchedLayoutData?.homePage?.landingCommitment?.commitmentTitle || 'Our Commitment',
                            commitmentDescription: ls.get('crmLandingCommitmentDescription') || savedLayoutData?.homePage?.landingCommitment?.commitmentDescription || fetchedLayoutData?.homePage?.landingCommitment?.commitmentDescription || 'We foster ongoing employee involvement...',
                            backgroundImage: ls.get('crmLandingCommitmentImage') || savedLayoutData?.homePage?.landingCommitment?.backgroundImage || fetchedLayoutData?.homePage?.landingCommitment?.backgroundImage || [],
                        },
                        landingNeedManpower: {
                            needManpowerText: ls.get('crmLandingNeedManpowerText') || savedLayoutData?.homePage?.landingNeedManpower?.needManpowerText || fetchedLayoutData?.homePage?.landingNeedManpower?.needManpowerText || 'Looking for Staffing Services?',
                            contactButtonText: ls.get('crmLandingNeedManpowerButtonText') || savedLayoutData?.homePage?.landingNeedManpower?.contactButtonText || fetchedLayoutData?.homePage?.landingNeedManpower?.contactButtonText || 'Contact Us',
                            contactButtonLink: ls.get('crmLandingNeedManpowerButtonLink') || savedLayoutData?.homePage?.landingNeedManpower?.contactButtonLink || fetchedLayoutData?.homePage?.landingNeedManpower?.contactButtonLink || '/become-partner',
                        },
                        landingTeam: {
                            welcomeTitle: ls.get('crmLandingTeamWelcomeTitle') || savedLayoutData?.homePage?.landingTeam?.welcomeTitle || fetchedLayoutData?.homePage?.landingTeam?.welcomeTitle || 'Welcome to Lorem Ipsum',
                            welcomeDescription: ls.get('crmLandingTeamWelcomeDescription') || savedLayoutData?.homePage?.landingTeam?.welcomeDescription || fetchedLayoutData?.homePage?.landingTeam?.welcomeDescription || 'Lorem Ipsum Staffing Resources Agency Corporation...',
                            whyTitle: ls.get('crmLandingTeamWhyTitle') || savedLayoutData?.homePage?.landingTeam?.whyTitle || fetchedLayoutData?.homePage?.landingTeam?.whyTitle || 'Why Lorem Ipsum',
                            whyDescription: ls.get('crmLandingTeamWhyDescription') || savedLayoutData?.homePage?.landingTeam?.whyDescription || fetchedLayoutData?.homePage?.landingTeam?.whyDescription || 'At Lorem Ipsum Staffing, we understand...',
                            teamImages: ls.get('crmLandingTeamImage') || savedLayoutData?.homePage?.landingTeam?.teamImages || fetchedLayoutData?.homePage?.landingTeam?.teamImages || [],
                        },
                      },
                      careerPage: {
                          careerHero: {
                              title: ls.get('crmCareerHeroText') || savedLayoutData?.careerPage?.careerHero?.title || fetchedLayoutData?.careerPage?.careerHero?.title || 'Career Opportunities',
                              backgroundImage: ls.get('crmCareerHeroImage') || savedLayoutData?.careerPage?.careerHero?.backgroundImage || fetchedLayoutData?.careerPage?.careerHero?.backgroundImage || 'default-career-hero.jpg',
                          },
                          careerTable: getParsedData(ls.get('crmCareerTableData')) || savedLayoutData?.careerPage?.careerTable || fetchedLayoutData?.careerPage?.careerTable || [],
                          careerHowItWorks: {
                            steps: getParsedData(ls.get('crmCareerHowSteps')) || savedLayoutData?.careerPage?.careerHowItWorks?.steps || fetchedLayoutData?.careerPage?.careerHowItWorks?.steps || [],
                            icons: ls.get('crmCareerHowIcons') || savedLayoutData?.careerPage?.careerHowItWorks?.icons || fetchedLayoutData?.careerPage?.careerHowItWorks?.icons || [],
                          },
                          crmCareerHowText: ls.get('crmCareerHowText') || savedLayoutData?.careerPage?.crmCareerHowText || fetchedLayoutData?.careerPage?.crmCareerHowText || 'How it Works',
                      },
                      contactPage: {
                        contactHero: {
                            title: ls.get('crmContactHeroText') || savedLayoutData?.contactPage?.contactHero?.title || fetchedLayoutData?.contactPage?.contactHero?.title || 'Contact Us',
                            backgroundImage: ls.get('crmContactHeroImage') || savedLayoutData?.contactPage?.contactHero?.backgroundImage || fetchedLayoutData?.contactPage?.contactHero?.backgroundImage || 'default-contact-image.jpg',
                        },
                        contactForm: {
                            title: ls.get('crmContactFormText') || savedLayoutData?.contactPage?.contactForm?.title || fetchedLayoutData?.contactPage?.contactForm?.title || 'Message Us',
                            nameFieldLabel: ls.get('crmContactFormNameField') || savedLayoutData?.contactPage?.contactForm?.nameFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.nameFieldLabel || 'Name',
                            emailFieldLabel: ls.get('crmContactFormEmailField') || savedLayoutData?.contactPage?.contactForm?.emailFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.emailFieldLabel || 'Email Address',
                            contactFieldLabel: ls.get('crmContactFormContactField') || savedLayoutData?.contactPage?.contactForm?.contactFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.contactFieldLabel || 'Contact No.',
                            messageFieldLabel: ls.get('crmContactFormMessageField') || savedLayoutData?.contactPage?.contactForm?.messageFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.messageFieldLabel || 'Message',
                            submitButtonText: ls.get('crmContactFormSubmitText') || savedLayoutData?.contactPage?.contactForm?.submitButtonText || fetchedLayoutData?.contactPage?.contactForm?.submitButtonText || 'Send',
                            contactAddFields: ls.get('crmContactFieldAdded') || savedLayoutData?.contactPage?.contactForm?.contactAddFields || fetchedLayoutData?.contactPage?.contactForm?.contactAddFields || []
                        }
                      },
                      newsPage: {
                        newsHero: {
                            title: ls.get('crmNewsHeroText') || savedLayoutData?.newsPage?.newsHero?.title || fetchedLayoutData?.newsPage?.newsHero?.title || 'News and Events',
                            backgroundImage: ls.get('crmNewsHeroImage') || savedLayoutData?.newsPage?.newsHero?.backgroundImage || fetchedLayoutData?.newsPage?.newsHero?.backgroundImage || 'default-news-hero.jpg',
                        },
                        newsCalendar: ls.get('crmNewsEvents') || savedLayoutData?.newsPage?.newsCalendar?.newsAndEvents || fetchedLayoutData?.newsPage?.newsCalendar?.newsAndEvents || [
                            {"date": "2022-01-01", "event": "New Year's Day", "description": "New Year's Day is a federal holiday in the United States."},
                            {"date": "2022-02-14", "event": "Valentine's Day", "description": "Valentine's Day is a special day for couples to celebrate their love."},
                            {"date": "2022-03-17", "event": "St. Patrick's Day", "description": "St. Patrick's Day is a day to celebrate Irish heritage and culture."},
                            {"date": "2022-04-15", "event": "Good Friday", "description": "Good Friday is a Christian holiday that commemorates the crucifixion of Jesus Christ."},
                            {"date": "2022-05-01", "event": "May Day", "description": "May Day is a traditional spring holiday in many countries."},
                            {"date": "2022-06-15", "event": "Father's Day", "description": "Father's Day is a special day to celebrate fathers and father figures."},
                            {"date": "2022-07-01", "event": "Canada Day", "description": "Canada Day is a national holiday in Canada."},
                            {"date": "2022-08-15", "event": "Assumption Day", "description": "Assumption Day is a Catholic holiday that commemorates the assumption of the Virgin Mary into heaven."},
                            {"date": "2022-09-01", "event": "Labor Day", "description": "Labor Day is a federal holiday in the United States that honors the contributions and achievements of American workers."},
                            { "date": "2022-10-01", "event": "Thanksgiving Day", "description": "Thanksgiving Day is a national holiday in the United States that celebrates the harvest and expresses gratitude for the blessings of the past year."},
                            { "date": "2022-11-01", "event": "Halloween", "description": "Halloween is a popular holiday in the United States that is celebrated on October 31st."},
                            { "date": "2022-12-25", "event": "Christmas Day", "description": "Christmas Day is a federal holiday in the United States that commemorates the birth of Jesus Christ."},
                          ],
                      },
                      aboutPage: {
                        aboutHero: {
                          backgroundImage: ls.get('crmAboutUsHeroImage') || savedLayoutData?.aboutPage?.aboutHero?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutHero?.backgroundImage || '',
                          title: ls.get('crmAboutUsHeroText') || savedLayoutData?.aboutPage?.aboutHero?.title || fetchedLayoutData?.aboutPage?.aboutHero?.title || 'About Us',
                        },
                        aboutCenterText: {
                          title: ls.get('crmAboutUsCenterTextTitle') || savedLayoutData?.aboutPage?.aboutCenterText?.title || fetchedLayoutData?.aboutPage?.aboutCenterText?.title || 'History',
                          text: ls.get('crmHistory') || savedLayoutData?.aboutPage?.aboutCenterText?.text || fetchedLayoutData?.aboutPage?.aboutCenterText?.text || 'Lorem Ipsum history and background text.',
                        },
                        aboutContents: {
                          vision: ls.get('crmVision') || savedLayoutData?.aboutPage?.aboutContents?.vision || fetchedLayoutData?.aboutPage?.aboutContents?.vision || 'Our vision is to provide the best staffing solutions...',
                          mission: getParsedData(ls.get('crmMission')) || savedLayoutData?.aboutPage?.aboutContents?.mission || fetchedLayoutData?.aboutPage?.aboutContents?.mission || [
                            'Deliver excellent human resources services.',
                            'Provide innovative recruitment solutions.',
                            'Ensure client satisfaction through dedicated support.',
                          ],
                        },
                        aboutBanner: {
                          backgroundImage: ls.get('crmAboutBanner') || savedLayoutData?.aboutPage?.aboutBanner?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutBanner?.backgroundImage || '',
                        },
                        aboutServices: {
                          title: ls.get('crmAboutTextOurServices') || savedLayoutData?.aboutPage?.aboutServices?.title || fetchedLayoutData?.aboutPage?.aboutServices?.title || 'Our Services',
                          services: ls.get('crmAboutUsServicesImages') || savedLayoutData?.aboutPage?.aboutServices?.services || fetchedLayoutData?.aboutPage?.aboutServices?.services || [],
                          backgroundImage: ls.get('crmAboutUsServicesBackgroundImages') || savedLayoutData?.aboutPage?.aboutServices?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutServices?.backgroundImage || '',
                        },
                      },
                };

                console.log('Sending layout data:', layoutData);

                editSaveLayout(layoutData).then(response => {
                    if (response) {
                        console.log('Layout data saved successfully:', response);
                    } else {
                        console.error('Failed to save layout data');
                    }
                });
            } catch (error) {
                console.error('Error during handleEditMode:', error);
            }
        }
        return !prev;
    });
};

  const handlePublish = async() =>{
    const publishing = await publishTemplate()
    console.log(publishing)
    if(publishing){
      setIsPublished(true);
      window.alert("Successfully published");
    }else{
      window.alert("Unsuccessfull");
    }
  }

  const handleUnpublish = async () => {
    const unpublishing = await unpublishTemplate(); 
    if (unpublishing) {
      setIsPublished(false);  
      window.alert("Successfully unpublished");
    } else {
      window.alert("Unsuccessful");
    }
  };

  const handleMETADataSave = (metaTags) => {
    delete metaTags['_id']
    setMetaData(metaTags);
    const layoutData = {
      meta: metaTags
    }
    console.log('check meta: ', layoutData)
    editSaveLayout(layoutData).then(response => {
      if (response) {
          console.log('Layout data saved successfully:', response);
          const refresh = activeTemplate
          setActiveTemplate(refresh)
      } else {
          console.error('Failed to save layout data');
      }
    });
  }
  
  const handleSaveGA = async (data) =>{
    const addGA = await addGATracker(data)
    if(addGA){
      window.alert('Update GA tracker Successfully!')
    }
  }

  const [themeColors, setThemeColors] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
  });
  
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColors.primaryColor,
      },
      secondary: {
        main: themeColors.secondaryColor,
      },
    },
  });

  useEffect(() => {
    const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
    const initialPrimaryColor = savedLayoutData.primaryColor || primaryColor;
    const initialSecondaryColor = savedLayoutData.secondaryColor || secondaryColor;
  
    setThemeColors({ primaryColor: initialPrimaryColor, secondaryColor: initialSecondaryColor });
  }, [primaryColor, secondaryColor]);

  return (
    <>
    <Dialog
      open={openPublishURL}
      onClose={handlePublishURLClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Publish Control"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Your site's URL: </Typography>
            <Link href={url} target="_blank" rel="noopener noreferrer" sx={{wordBreak: 'break-all'}}>{url}</Link>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
                    const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
                    const layoutData = {
                      primaryColor: savedLayoutData.primaryColor || primaryColor,
                      secondaryColor: savedLayoutData.secondaryColor || secondaryColor,
                      header,
                      footer,
                      homePage,
                      careerPage,
                      contactPage,
                      newsPage,
                      aboutPage,
                    };
                    localStorage.setItem('layoutData', JSON.stringify(layoutData));
                    if (isPublished) {
                      handleUnpublish();
                    } else {
                      handlePublish();
                    }
                  }} color={isPublished ? "error" : "success"}>
          {isPublished ? " Unpublish" : " Publish"}
        </Button>
        <Button onClick={handlePublishURLCopy} color="primary">
          Copy
        </Button>
        <Button onClick={handlePublishURLClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
      <Grid container spacing={10} sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Grid item sx={{flexGrow: 1}}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenTemplate(true)}
                endIcon={<Icon icon="gg:template" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                Templates
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenTheme(true)}
                endIcon={<Icon icon="arcticons:vivo-themes" width="2rem" height="2rem"  style={{color: 'white'}} />}
              >
                Theme Color
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenMedia(true)}
                style={{
                  width: "auto",
                  transition: "width 0.3s ease",  
                  overflow: "hidden",             
                  whiteSpace: "nowrap",          
                  justifyContent: "flex-start",
                  paddingRight: "16px", 
                }}
                endIcon={<Icon icon="material-symbols-light:perm-media-outline" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                {'Media Library'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenMeta(true)}
                style={{
                  width: "auto", 
                  transition: "width 0.3s ease",  
                  overflow: "hidden",             
                  whiteSpace: "nowrap",          
                  justifyContent: "flex-start",
                  paddingRight:"16px", 
                }}
                endIcon={<Icon icon="icon-park-outline:seo" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                {'Meta Tags'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
           {selectedTemplate && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  color={isEditMode ? "success" : "secondary"} 
                  onClick={handleEditMode}
                  style={{
                    width: "auto",  
                    transition: "width 0.3s ease",  
                    overflow: "hidden",             
                    whiteSpace: "nowrap",          
                    justifyContent: "flex-start", 
                    paddingRight: "16px",  
                  }}
                  endIcon={<Icon icon={!!isEditMode ? "ic:sharp-edit-off" : "ic:outline-edit"} width="2rem" height="2rem" style={{color: 'white'}} />}
                >
                  {!!isEditMode ? <>Exit</> : <>Edit</>}
                </Button>
              </Grid>
              <Grid item>
              <div>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: "auto",  
                  transition: "width 0.3s ease",  
                  overflow: "hidden",             
                  whiteSpace: "nowrap",          
                  justifyContent: "flex-start", 
                  paddingRight: "16px",  
                }}
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<Icon icon={"ic:sharp-keyboard-arrow-down"} width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                Manage Site
              </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                   onClick={() => {
                    const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
                    const layoutData = {
                      primaryColor: savedLayoutData.primaryColor || '#000000',
                      secondaryColor: savedLayoutData.secondaryColor || '#FFA500',
                      header,
                      footer,
                      homePage,
                      careerPage,
                      contactPage,
                      newsPage,
                      aboutPage,
                    };
                    localStorage.setItem('layoutData', JSON.stringify(layoutData)); 
                    window.open('/#/preview', '_blank'); 
                  }}>
                    <Icon icon="icon-park-outline:preview-open" width="2rem" height="2rem" style={{ color: 'black', marginRight: '0.5rem' }}/>
                    {'Preview'}
                  </MenuItem>
                  <MenuItem
                   onClick={() => setOpenPublishURL(true)}>
                    <Icon
                      icon={"material-symbols:publish"}
                      width="2rem"
                      height="2rem"
                      style={{ color: 'black', marginRight: '0.5rem' }}
                    />
                    {"Publish Control"}
                  </MenuItem>
                  <MenuItem
                   onClick={() => setOpenTracking(true)}><Icon icon="ant-design:signal-filled" style={{ color: "black", marginRight: '0.5rem' }}/>{"GA-Tracking Code"}</MenuItem>
                </Menu>
                </div>
              </Grid>
            </>
           )}
          </Grid>
        </Grid>
      </Grid>

      <ThemeProvider theme={theme}>
        <Box sx={{marginTop: '8px'}}>
          {isEditMode && (
            <AppBar position="static" color="secondary">
              <Toolbar>
                <Typography variant="h6">
                  Edit Mode is On
                </Typography>
              </Toolbar>
            </AppBar>
          )}
        </Box>
        <ContentStyle>
          <EditModeContext.Provider value={{ isEditMode }}>
            <EditMetaTagsDialog onClose={handleCloseMeta} open={openMeta} onSave={handleMETADataSave} metaData={metaData} />
            
            {loading ? <Loader/> : (selectedTemplate ? selectedTemplate : <div>Please select a template.</div>)}
          </EditModeContext.Provider>
        </ContentStyle>
      </ThemeProvider>

      <TemplateDialog
        openTemplate={openTemplate}
        handleCloseTemplate={handleCloseTemplate}
        onSelect={handleSelectTemplate}
        activeTemplate={activeTemplate}
        setActiveTemplate={setActiveTemplate}
        setThemeColors={setThemeColors}
      />

      <ThemeDialog 
        openMedia={openTheme}
        handleCloseMedia={handleCloseTheme}
        setThemeColors={setThemeColors} 
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
      <MediaDialog
        openMedia={openMedia}
        handleCloseMedia={handleCloseMedia}
        eventButton='indexButton'
      />
      <TrackingGADialog 
        openTracking={openTracking}
        handleCloseTracking={handleCloseTracking}
        handleSaveGA={handleSaveGA}
      />
    </>
  );
};
