import React from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
// Components
import ContactHero from './contactHero'
import ContactForm from './contactForm'
import { Helmet } from 'react-helmet';

const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function ContactPage({ metaData, contactPageData }) {
  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box sx={{height: '100%', overflowY: 'auto'}} id="move_top">
      <ContentStyle>
      <ContactHero data={contactPageData?.contactHero} />
      <ContactForm data={contactPageData?.contactForm} />
      </ContentStyle>
    </Box>
    </>
  )
}