import React, {useEffect, useState} from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import CareerHero from './careerHero'
import CareerTable from './careerTable'
import CareerHowItWorks from './careerHowItWorks'
import SecureLS from 'secure-ls'
import { Helmet } from 'react-helmet';

const ls = new SecureLS({encodingType: 'aes'})

const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function CareerPage({metaData, careerPageData}) {
  console.log('careerPageData: ', careerPageData)

  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box sx={{height: '100%', overflowY: 'auto'}} id="move_top">
      <ContentStyle>
        <CareerHero data={careerPageData?.careerHero} />
        <Box sx={{mt: 8, px: {xs: 4, md: 6}}}>
          <CareerTable data={careerPageData?.careerTable} />
          <CareerHowItWorks
           data={{
            crmCareerHowText: careerPageData?.crmCareerHowText,
            careerHowItWorks: careerPageData?.careerHowItWorks,
          }}
          />
        </Box>
      </ContentStyle>
    </Box>
    </>
  )
}
