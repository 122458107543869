import {APICRM,API, SECRET} from './config'

export const formData = _data => {
  let new_formData = new FormData()
  for (var key in _data) {
    new_formData.append(key, _data[key])
  }
  return new_formData
}
const post = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: 'POST',
    headers: {
      'X-API-KEY': `${SECRET}`,
    },
    body: formData(_data),
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const get = async _url => {
  return await fetch(`${API}/${_url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const getStarjobs = async (company) =>{
  return await fetch(`https://api.starjobs.com.ph/webservice-hr-api/internal/applicants/failed/${company}`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const updateStarbjobs = async (id, status) =>{
  return await fetch(`https://api.starjobs.com.ph/webservice-hr-api/internal/applicants/update/status/${id}/${status}`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    }
  })
  .then(response => {
    return response.json()
  })
  .catch(err => {
    console.log(err)
  })
}

const patch = async (_data, _url) => {
  return await fetch(`${APICRM}${_url}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
    body: JSON.stringify(_data),
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const remove = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const postCrm = async (_data, _url) => {
  return await fetch(`${APICRM}${_url}`, {
    method: 'POST',
    headers: {
      'X-API-KEY': `${SECRET}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data),
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed with status ' + response.status);
      }
    })
    .catch(err => {
      console.error('Error in postCrm:', err);
      return false;
    });
};

const postCrmImage = async (_data, _url) => {
  console.log("postCrmImage", _data)
  return await fetch(`${APICRM}${_url}`, {
    method: 'POST',
    headers: {
      'X-API-KEY': `${SECRET}`,
    },
    body: _data,
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed with status ' + response.status);
      }
    })
    .catch(err => {
      console.error('Error in postCrm:', err);
      return false;
    });
};

const getCrm = async (_url) => {
  console.log(`Fetching data from ${_url}`);
  return await fetch(`${APICRM}${_url}`, {
    method: 'GET',
    headers: {
      'X-API-KEY': `${SECRET}`,
      'Content-Type': 'application/json', 
    },
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed with status ' + response.status);
      }
    })
    .catch(err => {
      console.error('Error in getCrm:', err);
      return false;
    });
};

const updateCrm  = async (_data, _url) =>{
  return await fetch(`${APICRM}${_url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
    body: JSON.stringify(_data)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const deleteCrm = async (_url) =>{
  return await fetch(`${APICRM}${_url}`,{
    method: 'Delete',
    headers:{
      'X-API-KEY':`${SECRET}`
    }
  })
  .then(response => {
    return response.json()
  })
  .catch(err => {
    console.log(err)
  })
}

export {post, get, patch, remove, getStarjobs, updateStarbjobs, postCrm, getCrm, updateCrm, postCrmImage, deleteCrm}
