import React, { useContext, useState, useEffect } from 'react'
import {styled, useTheme} from '@mui/material/styles'
import {Container, Typography, Box, Card, CardContent, Backdrop, CircularProgress} from '@mui/material'
import cover from '../../assets/wavy-two.png'
import layout1Manpower from '../../assets/layout1-manpower.jpg'
import whyLayout1 from '../../assets/why-layout1.jpg'
import {motion} from 'framer-motion'
import SecureLS from 'secure-ls'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import ImageUploadDialog from '../../../../image/ImageWithDialog';

const RootStyle = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  overflow: 'visible',
  position: 'relative',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
}))

const ls = new SecureLS({encodingType: 'aes'})

export default function LandingTeam( data ) {
  const theme = useTheme()

  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState([`Welcome to `, `Lorem Ipsum`, `Lorem Ipsum Staffing Resources Agency Corporation is a dedicated team of operations and human resource 
professionals with expertise in recruitment and talent management. We are committed to helping the 
Filipino community by connecting individuals with meaningful employment opportunities. Our mission is to 
become a leading provider of workforce solutions that add value to our clients. Our team is composed of 
experienced Human Resources managers, and our recruiters excel at identifying top talent in the market and 
aligning them with our clients' needs.`, `Why `, `Lorem Ipsum`, `At Lorem Ipsum Staffing, we understand that having the right people is the most important asset for any 
business. We've built a strong network of skilled professionals who can help your company meet its key 
goals and complete critical projects. In today's competitive market, finding top talent is more 
challenging than ever, but that's where we excel—by connecting you with the perfect candidates. Our 
selection process is thorough and ensures that you receive only the best talent, all while offering 
services that are significantly more cost-effective than other providers. Unlike others who spend heavily 
on advertising and personnel costs without guaranteeing the best hires, we deliver both quality and value.`]); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([layout1Manpower, whyLayout1, cover]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    const passedData = data?.data || {}; 
    
    const storedWelcomeTitle = ls.get('crmLandingTeamWelcomeTitle');
    const storedWelcomeDescription = ls.get('crmLandingTeamWelcomeDescription');
    const storedWhyTitle = ls.get('crmLandingTeamWhyTitle');
    const storedWhyDescription = ls.get('crmLandingTeamWhyDescription');
    const storedImage = ls.get('crmLandingTeamImage');
    
    const defaultWelcomeTitle = 'Welcome to Lorem Ipsum';
    const defaultWhyTitle = 'Why Lorem Ipsum';
    
    setTextFields([
      storedWelcomeTitle || passedData?.welcomeTitle || defaultWelcomeTitle,
      'Lorem Ipsum',
      storedWelcomeDescription || passedData?.welcomeDescription || `Lorem Ipsum Staffing Resources Agency Corporation is a dedicated team of operations and human resource professionals with expertise in recruitment and talent management. We are committed to helping the Filipino community by connecting individuals with meaningful employment opportunities. Our mission is to become a leading provider of workforce solutions that add value to our clients. Our team is composed of experienced Human Resources managers, and our recruiters excel at identifying top talent in the market and aligning them with our clients' needs.`,
      storedWhyTitle || passedData?.whyTitle || defaultWhyTitle,
      'Lorem Ipsum',
      storedWhyDescription || passedData?.whyDescription || `At Lorem Ipsum Staffing, we understand that having the right people is the most important asset for any business. We've built a strong network of skilled professionals who can help your company meet its key goals and complete critical projects. In today's competitive market, finding top talent is more challenging than ever, but that's where we excel—by connecting you with the perfect candidates. Our selection process is thorough and ensures that you receive only the best talent, all while offering services that are significantly more cost-effective than other providers. Unlike others who spend heavily on advertising and personnel costs without guaranteeing the best hires, we deliver both quality and value.`,
    ]);

    setImageUrl(storedImage || passedData?.teamImages || [layout1Manpower, whyLayout1, cover]);
  }, [data]);


  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
      setDialogValue(textFields[index]);
      setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
  
    if (openDialogIndex === 0 || openDialogIndex === 3) {
      const splitText = dialogValue.split(' ');
      const title = splitText.slice(0, -1).join(' ');
      const lastWord = splitText.slice(-1)[0];
  
      if (openDialogIndex === 0) {
        newValues[0] = `${title} ${lastWord}`;
      } else if (openDialogIndex === 3) {
        newValues[3] = `${title} ${lastWord}`;
      }
    } else {
      newValues[openDialogIndex] = dialogValue;
    }
  
    setTextFields(newValues);
  
    // Save each updated field back to local storage
    ls.set('crmLandingTeamWelcomeTitle', newValues[0]);
    ls.set('crmLandingTeamWelcomeDescription', newValues[2]);
    ls.set('crmLandingTeamWhyTitle', newValues[3]);
    ls.set('crmLandingTeamWhyDescription', newValues[5]);
  
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    handleImageLoading()
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmLandingTeamImage', updatedImages); // Save the new image in local storage
    handleImageLoaded()
  };

  const handleImageLoading = () => {
    setImageLoading(true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <>
    <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: 5 })}
      open={imageLoading}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
    <EditTextDialog
      open={openDialogIndex !== null}
      value={dialogValue}
      onClose={handleDialogClose}
      onSave={handleDialogSave}
      onChange={setDialogValue}
    />
    <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
    />
    <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(2)}>
    <RootStyle
      sx={{
        backgroundImage: `url(${imageUrl[2] !== 'wavy-two' ? imageUrl[2] : cover})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 130%',
        backgroundColor: '#FFFFFF',
        mt: {md: 8, sm: 0, xs: 10},
        mb: {md: 8, sm: 0, xs: 10},
        paddingTop: {md: 10, xs: 0},
        paddingBottom: {md: 10, xs: 0},
      }}
    >
      <Container maxWidth="lg">
        <Card
          sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            alignItems: 'stretch',
            mb: 12,
            boxShadow: 3,
            borderRadius: 2,
            height: 'auto',
            backgroundColor: 'transparent',
          }}
        >
          
          <CardContent
            sx={{
              width: {xs: '100%', md: '60%'},
              flex: '1',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 'auto',
              backgroundColor: 'transparent',
            }}
          >
            <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
              <Typography
                variant="h3"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                component="h2"                
              >
                {textFields[0].split(' ').slice(0, -1).join(' ')}{' '}
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    color: theme.palette.secondary.main || '#FFA500',
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  {textFields[0].split(' ').slice(-1)[0]}
                </Typography>
              </Typography>
              </EditableItemWrapper>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(2)}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.text.secondary,
                  mt: 1,
                  fontWeight: 400,
                  pr: {xs: 0, md: 10},
                  fontFamily: 'Circular Std, sans-serif',
                }}
                component="p"               
              >
                {textFields[2]}
              </Typography>
              </EditableItemWrapper>
            </CardContent>
            <Box
              sx={{
                position: 'relative', // Make the box a reference for absolute positioning
                width: { xs: '100%', md: '40%' },
                height: 'auto',
                display: 'block',
                borderRadius: { xs: '0 0 4px 4px', md: '4px 0 0 4px' },
                cursor: !!isEditMode ? 'pointer' : 'default',
                overflow: 'hidden', // Ensure no overflow for rounded corners
                '&:hover::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: !!isEditMode ? 'rgba(0, 0, 0, 0.1)' : 'inherit',
                  backdropFilter: !!isEditMode ? 'brightness(90%)' : 'none', // Darkens the image by 10%
                },
                '&:hover::before': {
                  content: !!isEditMode && '"Edit"',
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: '#fff',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                },
              }}
              onClick={(e) => {e.stopPropagation(); !!isEditMode && handleImageClick(0)}}
            >
              <Box
                component="img"
                src={imageUrl[0] !== 'layout1-manpower' ? imageUrl[0] : layout1Manpower}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: { xs: '0 0 4px 4px', md: '4px 0 0 4px' },
                }}
              />
            </Box>
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row-reverse'},
            alignItems: 'stretch',
            mb: 4,
            boxShadow: 3,
            borderRadius: 2,
            height: 'auto',
            backgroundColor: 'transparent',
          }}
        >
          <CardContent
            sx={{
              flex: '1',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 'auto',
              backgroundColor: 'transparent',
            }}
          >
            <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(3)}>
             <Typography
              variant="h3"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightBold,
              }}
              component="h2"              
              >
                {textFields[3].split(' ').slice(0, -1).join(' ')}{' '}
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    color: theme.palette.secondary.main || '#FFA500',
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  {textFields[3].split(' ').slice(-1)[0]}
                </Typography>
              </Typography>
              </EditableItemWrapper>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(5)}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.text.secondary,
                  mt: 1,
                  fontWeight: 400,
                  pr: {xs: 0, md: 10},
                  fontFamily: 'Circular Std, sans-serif',
                }}
                component="p"      
              >
                {textFields[5]}
              </Typography>
              </EditableItemWrapper>
              </CardContent>
              <Box
              sx={{
                position: 'relative', // Make the box a reference for absolute positioning
                width: { xs: '100%', md: '40%' },
                height: 'auto',
                display: 'block',
                borderRadius: { xs: '0 0 4px 4px', md: '4px 0 0 4px' },
                cursor: !!isEditMode ? 'pointer' : 'default',
                overflow: 'hidden', // Ensure no overflow for rounded corners
                '&:hover::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: !!isEditMode ? 'rgba(0, 0, 0, 0.1)' : 'inherit',
                  backdropFilter: !!isEditMode ? 'brightness(90%)' : 'none', // Darkens the image by 10%
                },
                '&:hover::before': {
                  content: !!isEditMode && '"Edit"',
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: '#fff',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                },
              }}
              onClick={(e) => {e.stopPropagation(); !!isEditMode && handleImageClick(1)}}
            >
              <Box
                component="img"
                src={imageUrl[1] !== 'why-layout1' ? imageUrl[1] : whyLayout1}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: { xs: '0 0 4px 4px', md: '4px 0 0 4px' },
                }}
              />
            </Box>
            </Card>
      </Container>
    </RootStyle>
    </EditableItemWrapper>
    </>
  )
}