import {postCrm, getCrm, updateCrm, postCrmImage, deleteCrm} from 'api/utils/requests'
import SecureLS from 'secure-ls'

const ls = new SecureLS({encodingType: 'aes'})


const   PUBLISH_TEMPLATE = `api/crm/publish`
const   UNPUBLISH_TEMPLATE = `api/crm/unpublish`
const GET_PUBLISHED = `api/crm/publish`
const ADD_GA_TRACKER = `api/crm/ga-trackercode`
const GET_GA_TRACKER = `api/crm/ga-trackercode/`
export const activateTemplate = async templateId => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
      templateId,
    }

    const response = await postCrm(data, 'api/crm/activate-layout')
    if (response) {
      console.log('Layout activated:', response)
      return response
    } else {
      console.error('Failed to activate layout')
      return null
    }
  } catch (error) {
    console.error('Error in activateTemplate:', error)
    return null
  }
}

export const deactivateTemplate = async () => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
    }

    const response = await postCrm(data, 'api/crm/deactivate-layout')
    if (response) {
      console.log('Layout deactivated:', response)
      return response
    } else {
      console.error('Failed to deactivate layout')
      return null
    }
  } catch (error) {
    console.error('Error in deactivateTemplate:', error)
    return null
  }
}

export const fetchAppliedLayout = async () => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const response = await getCrm(`api/crm/layout/${companyName}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  } catch (error) {
    console.error('Error in fetchAppliedLayout:', error)
    return null
  }
}



export const publishTemplate = async ()=> {
    let companyName = ls.get('company')
    let _url = `${PUBLISH_TEMPLATE}`
    const data = {companyName}
    const response = await updateCrm(data, _url)
    if(response){
      return true
    }
    return false
  }

  export const unpublishTemplate = async () => {
    try {
      const companyName = ls.get('company');
      if (!companyName) {
        throw new Error('Company name not found in SecureLS.');
      }
  
      const data = { companyName };
      const response = await updateCrm(data, UNPUBLISH_TEMPLATE); 
      if (response) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error in unpublishTemplate:', error);
      return false;
    }
  };


  export const getPublished = async () => {
    try {
      const companyName = ls.get('company')
      if (!companyName) {
        throw new Error('Company name not found in SecureLS.')
      }
  
      const response = await getCrm(`api/crm/publish/${companyName}`)
      if (response) {
        console.log('Fetched layout:', response)
        return response
      } else {
        console.error('Failed to fetch layout')
        return null
      }
    } catch (error) {
      console.error('Error in fetchAppliedLayout:', error)
      return null
    }
  }

export const editSaveLayout = async layoutData => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
      ...layoutData,
    }

    const response = await postCrm(data, 'api/crm/edit-layout')
    if (response) {
      console.log('Layout edited and saved:', response)
      return response
    } else {
      console.error('Failed to edit and save layout')
      return null
    }
  } catch (error) {
    console.error('Error in editSaveLayout:', error)
    return null
  }
}

export const checkSubdomain = async subdomain => {
  try {
    console.log('Checking subdomain:', subdomain)
    const response = await getCrm(`api/crm/public/check-subdomain/${subdomain}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  } catch (error) {
    console.error('Error in fetchAppliedLayout:', error)
    return null
  }
}

export const addGATracker = async tracker =>{
  try{
    const companyName = ls.get('company')
    console.log("CompanyName Addtracker =>", companyName)
    const data = {gaTracker: tracker, companyName: companyName}
    const response = await postCrm(data, ADD_GA_TRACKER)
    if(response){
      return response
    }else{
      return null
    }
  }catch(error){
    console.log("Error Saving Data GATracker", error)
  }
}

export const getGATracker = async (companyName) =>{
  try{
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    const response = await getCrm(`api/crm/ga-trackercode/${companyName}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  }catch(error){
    console.log("Error", error)
  }
}

export const uploadImage = async (image, companyName) => {
  try {
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    if (!image) {
      throw new Error('No image found')
    }

    console.log('Uploading image:', image)
    const response = await postCrmImage(image, `api/crm/save-media/${companyName}`)

    if (!response) {
      console.error('Failed to upload image')
      return null
    }

    return response
  } catch (error) {
    console.error('Error in uploadImage:', error)
    return null
  }
}

export const deleteImage = async (imageName, companyName) => {
  try {
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    if (!imageName) {
      throw new Error('No image name found')
    }

    const response = await deleteCrm(`api/crm/media/${companyName}/${imageName}`)

    if (!response) {
      console.error('Failed to upload image')
      return null
    }

    return response
  } catch (error) {
    console.error('Error in uploadImage:', error)
    return null
  }
}
