import React,{useEffect, useState, useContext} from 'react'
import {styled, useTheme} from '@mui/material/styles'
import { Container, Typography, Grid, Box } from '@mui/material';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { motion } from 'framer-motion';

import RichTextEditor from '../../../../modal/rich-text-editor'
import SecureLS from 'secure-ls'

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  overflow: 'hidden',
  position: 'relative',
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center'
}));

const ls = new SecureLS({encodingType: 'aes'})

export default function AboutCenterText({ data }) {
  const theme = useTheme();

  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['History']);
  const [openDialogIndex, setOpenDialogIndex] = useState(null); 
  const [textType, setTextType] = useState()
  const [textValue, setTextValue] = useState('')
  const [dialogValue, setDialogValue] = useState('');
  const [textDialogOpen, setTextDialogOpen] = useState(false)
  const [historyContent, setHistoryContent] = useState(`<p>Lorem ipsum dolor sit amet, 
    consectetur adipiscing elit. Vivamus 
    lacinia odio vitae vestibulum. Fusce ultricies 
    tortor eget vehicula cursus. Nulla facilisi. Sed 
    ullamcorper massa in odio lacinia, at tincidunt 
    erat fringilla. Cras eget eros id quam volutpat 
    malesuada. Duis nec tincidunt nisi. Integer id 
    interdum ipsum. Curabitur at suscipit risus. Integer 
    sed elit vel mauris facilisis pharetra.</p>
`)
  const { isEditMode } = context || {};

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleTextdialogClose = () =>{
    setTextDialogOpen(false)
  } 

  const handleTextClickContent = (type) =>{
    
    if(type === 'History'){
      setTextType('History')
      setTextValue(historyContent)
    }
    setTextDialogOpen(true)
  }


  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);
    ls.set('crmLandingCenterMainText', newValues[0]);
  };

  const onUpload = (html) =>{
    if(textType === 'History'){
      ls.set('crmHistory', html);
      setHistoryContent(html)
    }
  }


  useEffect(() => {
    const passedData = data || {}; 
    
    const storedMainText = ls.get('crmAboutUsCenterText');
    setTextFields([
      storedMainText || passedData?.title || 'History' 
    ]);
  }, [data]);
  
  useEffect(() => {
    const passedData = data || {}; 
    const storedHistory = ls.get('crmHistory');
    
    if (storedHistory) {
      setHistoryContent(storedHistory); 
    } else if (passedData?.text) {
      console.log("data:", passedData)
      setHistoryContent(passedData.text);  
    } else {
      setHistoryContent(`<p>Lorem ipsum dolor sit amet, 
        consectetur adipiscing elit. Vivamus 
        lacinia odio vitae vestibulum. Fusce ultricies 
        tortor eget vehicula cursus. Nulla facilisi. Sed 
        ullamcorper massa in odio lacinia, at tincidunt 
        erat fringilla. Cras eget eros id quam volutpat 
        malesuada. Duis nec tincidunt nisi. Integer id 
        interdum ipsum. Curabitur at suscipit risus. Integer 
        sed elit vel mauris facilisis pharetra.</p>`);  
    }
  }, [data]);

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', ml: { xs: 0 } }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              pl: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div 
            initial={{y: 50, opacity: 0}} 
            animate={{y: 0, opacity: 1}} 
            transition={{duration: 0.55}} 
            style={{ 
              marginTop: 0,
              width:'100%',
              padding:'2rem'
             }}>
             <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)}>
              <Typography
                  variant="h3"
                  sx={{
                    color: 'common.black',
                    margin: 'auto',
                    mt: { xs: 5, sm: 0, md: 0 }
                  }}
                  component="h2"                 
                >
                  {textFields[0]}
                </Typography>
             </EditableItemWrapper>
             <EditableItemWrapper onClick={() => !!isEditMode && handleTextClickContent('History')} >
             <div 
              dangerouslySetInnerHTML={{ __html: historyContent }}             
              style={{ 
                whiteSpace: 'normal', 
                wordWrap: 'break-word',  
                overflowWrap: 'break-word',  
                overflow: 'hidden',  
                maxWidth: '100%',  
              }}
              />
             </EditableItemWrapper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
       <RichTextEditor
        openTextDialog={textDialogOpen}
        oncloseDialog={handleTextdialogClose}
        onUpload={onUpload}
        value={textValue}
      />
    </RootStyle>
  );
}
