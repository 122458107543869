import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayout from 'components/crm/templates/first-template/layout';
import { fetchAppliedLayout } from 'api/private/crm'; // Import the fetch API call
import SecureLS from 'secure-ls';

export const PublishPage = () => {
  const [layoutData, setLayoutData] = useState({});
  const [loading, setLoading] = useState(true);
  const ls = new SecureLS({ encodingType: 'aes' });

  useEffect(() => {
    const getLayout = async () => {
      try {
        const response = await fetchAppliedLayout();
        const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};

        if (response && response.layout) {
          const { templateName, header, footer, homePage, careerPage, contactPage, newsPage, aboutPage, primaryColor: fetchedPrimaryColor, secondaryColor: fetchedSecondaryColor } = response.layout;

          const primaryColor = savedLayoutData.primaryColor || fetchedPrimaryColor || '#000000';
          const secondaryColor = savedLayoutData.secondaryColor || fetchedSecondaryColor || '#ffffff';

          const layoutToSave = {
            primaryColor,
            secondaryColor,
            header,
            footer,
            homePage,
            careerPage,
            contactPage,
            newsPage,
            aboutPage,
          };

          setLayoutData(layoutToSave);
          ls.set('fetchedLayoutData', JSON.stringify(layoutToSave)); // Save the fetched data to secure local storage
        } else {
          console.error('Layout data is not found in the response.');
        }
      } catch (error) {
        console.error('Failed to fetch the applied layout', error);
      } finally {
        setLoading(false);
      }
    };

    getLayout();
  }, []);
 
  const theme = createTheme({
    palette: {
      primary: {
        main: layoutData.primaryColor || '#000000',
      },
      secondary: {
        main: layoutData.secondaryColor || '#ffffff',
      },
    },
  });

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  return (
    <ThemeProvider theme={theme}>
      <MainLayout
        header={layoutData.header}
        footer={layoutData.footer}
        homePageData={layoutData.homePage}
        careerPageData={layoutData.careerPage} 
        contactPageData={layoutData.contactPage}
        newsPageData={layoutData.newsPage}
        aboutPageData={layoutData.aboutPage}
      />
    </ThemeProvider>
  );
};
