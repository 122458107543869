import React from 'react'
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
// components
//import Page from '../components/page';
import LandingBusiness from './landingBusiness';
import LandingCenterText from './landingCenterText';
import LandingCommitment from './landingCommitment';
import LandingNeedManpower from './landingNeedManPower';
import LandingTeam from './landingTeam';
// ----------------------------------------------------------------------

// const RootStyle = styled(Page)({
//   height: '100%'
// });

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function LandingPage({ metaData, handleNavigation, homePageData }) {
  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box sx={{ height: '100%', overflowY: 'auto' }} id="move_top">
      <ContentStyle>
        <LandingBusiness data={homePageData?.landingBusiness} />
        <LandingCenterText data={homePageData?.landingCenterText} />
        <LandingTeam data={homePageData?.landingTeam} />
        <LandingCommitment data={homePageData?.landingCommitment} />
        <LandingNeedManpower data={homePageData?.landingNeedManpower} handleNavigation={handleNavigation} />
      </ContentStyle>
    </Box>
    </>
  );
}