import React,{useState, useContext, useEffect} from 'react'
import {styled, useTheme} from '@mui/material/styles'
import { Container, Typography, Grid } from '@mui/material';
import { EditModeContext } from 'context/EditModeContext';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import RichTextEditor from '../../../../modal/rich-text-editor'
import SecureLS from 'secure-ls'

import { motion } from 'framer-motion';


const ls = new SecureLS({ encodingType: 'aes' })

const RootStyle = styled('div')(() => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 62,
  paddingBottom: 62,
  display: 'flex',
  alignItems: 'center'
}));
const ListStyle = styled('ol')(({ theme }) => ({
  color: theme.palette.common.black,
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 30,
    paddingRight: 30
  }
}));
const ListItemStyle = styled('li')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: '8px auto',
  [theme.breakpoints.up('xs')]: {
    width: '100%'
  }
}));

export default function AboutContents({ data }) {
  const context = useContext(EditModeContext);
  const theme = useTheme();
  const [textDialogOpen, setTextDialogOpen] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [textType, setTextType] = useState()
  const [visionContent, setVisionContent] = useState('By the year 2025, Xoxo Staffing Resources Agency Corporation envisions to be recognized as the leader in providing expert human resource services and solutions in the Philippines')
  const [missionContent, setMissionConent] = useState(`<p>Xoxo Staffing Resources Agency Corporation is committed to:</p>
    <ol>
      <li>Provide highly qualified and competent workforce with ethical values</li>
      <li>Deliver most efficient and innovative human resource services to the company we serve through quality assurance that includes collaborative needs assessment, comprehensive performance appraisal, consistent employee monitoring and engagement.</li>
      <li>Guarantee excellently trained personnel through a globally competitive holistic training and re-training program to ensure development, enrichment and success of our people.</li>
      <li>Ensure compliance with the Department of Labor and Employment standards and policies on employee wages, government financial institutions, holiday pay, 13th month pay and other mandated benefits.</li>
    </ol>`)




  const handleTextClick = (type) =>{
    
    if(type === 'Vision'){
      setTextType('Vision')
      setTextValue(visionContent)
    }else{
      setTextType('Mission')
      setTextValue(missionContent.toString())
    }
    setTextDialogOpen(true)
  }

  const handleTextdialogClose = () =>{
    setTextDialogOpen(false)
  } 

  const onUpload = (html) =>{
    if(textType === 'Mission'){
      ls.set('crmMission', html);
      setMissionConent(html)
    }else{
      ls.set('crmVision', html);
      setVisionContent(html)
    }
    
  }

  useEffect(() => {
    const passedData = data || {}; 
  
    const storedMission = ls.get('crmMission') || passedData.mission || `<p>Default mission content</p>`;
    const storedVision = ls.get('crmVision') || passedData.vision || 'Default vision content';
  
    setMissionConent(storedMission);
    setVisionContent(storedVision);
  }, [data]);


  const { isEditMode } = context || {};

  return (
    <RootStyle
      sx={{
        mb: { xs: 10, md: 10 },
        py: { xs: 0, md: 10 }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', margin: { xs: 0 }, ml: { xs: 0 } }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: '1rem',
              mx: 0,
              pl: { xs: '0 !important', md: '40px !important' },
              pr: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              style={{
                marginTop: 0,
                width: '100%',
                padding: '2rem',
                border: `2px solid ${theme.palette.primary.main || '#FBBA37'}`,
                height: '100%'
              }}
            >
              <Typography variant="h2" sx={{ color: 'common.black', mx: 2, textAlign: 'center' }} component="h2">
                Vision
              </Typography>
              
              <EditableItemWrapper onClick={() => !!isEditMode && handleTextClick('Vision')} >
                <div 
                  style={{
                    whiteSpace: 'normal', 
                    wordWrap: 'break-word',  
                    overflowWrap: 'break-word',  
                    overflow: 'hidden',  
                    maxWidth: '100%',  
                  }}
                  dangerouslySetInnerHTML={{ __html: visionContent }}                 
                />
              </EditableItemWrapper>
                
            </motion.div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '1rem',
              mx: 0,
              pl: { xs: '0 !important', md: '40px !important' },
              pr: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              style={{
                marginTop: 0,
                width: '100%',
                padding: '2rem',
                border: `2px solid ${theme.palette.primary.main || '#FBBA37'}`,
                height: '100%'
              }}
            >
              <Typography variant="h2" sx={{ color: 'common.black', mx: 2, textAlign: 'center' }} component="h2">
                Mission
              </Typography>

              <EditableItemWrapper onClick={() => !!isEditMode && handleTextClick('Mission')}>
              <div 
                style={{
                  whiteSpace: 'normal',  
                  wordWrap: 'break-word',  
                  overflowWrap: 'break-word',  
                  overflow: 'hidden',  
                  maxWidth: '100%', 
                }}
                dangerouslySetInnerHTML={{ __html: missionContent }}
                />
              </EditableItemWrapper>

            </motion.div>
          </Grid>
        </Grid>
      </Container>
      <RichTextEditor
        openTextDialog={textDialogOpen}
        oncloseDialog={handleTextdialogClose}
        onUpload={onUpload}
        value={textValue}
      />
    </RootStyle>
  );
}
