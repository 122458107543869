import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Input,
  Menu, MenuItem,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadImage, deleteImage } from 'api/private/crm';
import ImageUploadDialog from '../image/ImageWithDialog';
import SecureLS from 'secure-ls'
import { set } from 'lodash';

const ls = new SecureLS({encodingType: 'aes'})
const companyName = ls.get('company')
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FileUploadCard = ({ handleUpload, handleOpenImageUpload }) => {
  // const handleFileChange = (event) => {
  //   // const files = event.target.files;
  //   // if (files.length > 0) {
  //   //   handleUpload(files);
  //   //   event.target.value = null;
  //   // }
  // };

  return (
    <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
      {/* <Input
    //     accept="image/*,video/*"
    //     type="file"
    //     onChange={handleFileChange}
    //     inputProps={{ multiple: true }}
    //     style={{ display: 'none' }}
    //     id="file-upload-input"
    //   /> */}
      <label htmlFor="file-upload-input">
        <Box 
        sx={{display:'flex', 
        flexDirection: 'column',
        alignItems:'center',
        }}
        onClick={handleOpenImageUpload}
        >
          <Typography variant="body1" sx={{ mt: 1 }}>
          Add file
        </Typography>
          <IconButton component="span">
            <AddIcon fontSize="large" />
          </IconButton>
        </Box>
      </label>
     
    </Card>
  );
};

const MediaGrid = ({ mediaItems, handleUpload, handleSelected, handleDelete, handleOpenImageUpload, setError }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);

  const handleRadioChange = (itemName) => {
    setSelectedItem(itemName);
    handleSelected(itemName);
  };

  const handleMenuClick = (event, index) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuIndex(null);
  };
  
  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob(); 
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', fileName || 'downloaded_file');
  
      document.body.appendChild(link);
      link.click();
  
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url); 
      handleMenuClose(); 
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  

  const handleDeleteItem = (item) =>{
    handleDelete(item.imageName)
    handleMenuClose()
  }
  

  return (
    <Grid container spacing={2}>
      {mediaItems.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{ position: 'relative' }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {item && (
              <div style={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.imageUrl}
                  alt={item.imageName}
                  sx={{ transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}
                  onClick={() => handleRadioChange(item.imageUrl)}
                />
                {hoveredIndex === index && (
                  <>
                    <input
                      type="radio"
                      checked={selectedItem === item.imageUrl}
                      onChange={() => handleRadioChange(item.imageUrl)}
                      style={{
                        position: 'absolute',
                        top: '8px',
                        left: '8px',
                        zIndex: 1,
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                      }}
                      name="media-selection"
                    />
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 2,
                        color: 'white',
                      }}
                      onClick={(event) => {event.stopPropagation();handleMenuClick(event, index)}}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={menuIndex === index}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleDownload(item.imageUrl, item.imageName)}>
                        <DownloadIcon sx={{ mr: 1 }} />
                        Download
                      </MenuItem>
                      <MenuItem onClick={() => handleDeleteItem(item)}>
                        <DeleteIcon sx={{ mr: 1 }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            )}
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {item.imageName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={4}>
        <FileUploadCard handleUpload={handleUpload} handleOpenImageUpload={handleOpenImageUpload}/>
      </Grid>
    </Grid>
  );
};


export default function MediaDialog({ openMedia, handleCloseMedia, onImageSelect, eventButton }) {
  const [media, setMedia] = useState([]);
  const [imageSelected, setImageSelected] = useState()
  const [error, setError] = useState('');
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageCroppedSelected, setImageCroppedSelected] = useState()

  useEffect(() => {
    setError('');
  }, [openMedia]);

  const handleUpload = async (files) => {
    try {
      if(files){
        let updatedMedia
        const storedMedia = await ls.get('mediaAssets');
      
        updatedMedia = [...storedMedia, files];
        console.log("UpdatedMedia =>", updatedMedia)
        setMedia([...updatedMedia]);
        ls.set('mediaAssets', updatedMedia);
       alert('Successfully Uploaded!');
      }
    } catch (error) {
      console.error("Error uploading file", error);
      alert("Error uploading file");
    }
  };

  const handleOpenImageUpload = () =>{
    setImageDialogOpen(true)
  }


  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleClose = () => {
    handleCloseMedia();
  };

  const handleSave = () => {
    onImageSelect(imageSelected); 
    handleCloseMedia();
  };

  const handleSelected = (item) =>{
    console.log("handleSelected =>",item)
    setImageSelected(item)
  }

  const handleDelete = async(imageName) => {
    
    const response = await deleteImage(imageName, companyName)
    if(response){
      console.log(response)
      const newMedia = response.body;
      const updatedMedia = [...newMedia];
      setMedia([...newMedia]);
      ls.set('mediaAssets', updatedMedia);
    }
  };

  useEffect(() => {
    const storedMedia = ls.get('mediaAssets');
    if (storedMedia) {
      setMedia(storedMedia); 
    }
  }, [openMedia]);

  return (
    <>
      <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleUpload}
      currentImage={imageCroppedSelected}
      comoponentRequest="mediaDialog"
    />
    <Dialog
      open={openMedia}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseMedia}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={false}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 'none' } }}
    >
      <DialogTitle id="alert-dialog-slide-title">{'Manage your media'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">Showing {media.length} assets</DialogContentText>
        <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          <MediaGrid 
          mediaItems={media} 
          handleUpload={handleUpload} 
          handleSelected={handleSelected} 
          handleDelete={handleDelete}
          handleOpenImageUpload={handleOpenImageUpload}
          setError={setError}
          />
        </Box>
        {
          error && <Alert severity='error'>{error}</Alert>
        }
      </DialogContent>
      {eventButton == 'indexButton'? <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>:<DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Select
        </Button>
      </DialogActions>}
    </Dialog>
    </>
  );
}

